import {AnimatePresence} from 'framer-motion';

import {TRANSITION_SPEED_FAST} from '@/theme/transitions';
import Vector2 from '@/utils/math/Vector2';

import {ChartTooltipWrapper, StyledCopy} from './styles';

interface Props {
  copy: string;
  position: Vector2;
  visible: boolean;
  superscriptIndex?: number;
}

const ChartTooltip = ({copy, superscriptIndex, position, visible}: Props) => (
  <AnimatePresence>
    {visible && (
      <ChartTooltipWrapper
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: TRANSITION_SPEED_FAST}}
        $position={position}
      >
        <StyledCopy>
          {copy}
          <sup>{superscriptIndex}</sup>
        </StyledCopy>
      </ChartTooltipWrapper>
    )}
  </AnimatePresence>
);

export default ChartTooltip;
