import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import BaseMedia from '@/components/Media';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XL,
  SPACING_XS,
} from '@/theme/spacings';

export const StyledMedia = styled(BaseMedia)`
  &&& {
    @media ${QUERY_GREATER_THAN_MOBILE} {
      display: none;
    }
  }
`;

export const Eyebrow = styled(Heading)`
  margin: ${SPACING_M} 0 ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin: ${SPACING_XL} 0 ${SPACING_M};
  }
`;

interface Props {
  $hasEyebrow?: boolean;
}

export const StyledHeading = styled(Heading)<Props>`
  margin-bottom: ${SPACING_XS};

  ${({$hasEyebrow}) =>
    $hasEyebrow
      ? css`
          margin: 0 0 ${SPACING_S};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            margin-bottom: ${SPACING_M};
          }
        `
      : css`
          margin-top: ${SPACING_M};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            margin-top: ${SPACING_XL};
          }

          @media ${QUERY_GREATER_THAN_TABLET} {
            margin-top: ${SPACING_XL};
          }
        `}
`;

export const ParagraphWrapper = styled.div`
  margin-bottom: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-bottom: ${SPACING_XL};
  }
`;
