import {motion} from 'framer-motion';
import styled from 'styled-components';

import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {COLOR_BLACK, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_S} from '@/theme/spacings';
import {TYPE_SIZE_SANS_SMALL} from '@/theme/type';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

export const PopupWrapper = styled.span`
  position: relative;
`;

export const StyledPopup = styled(motion.div)`
  position: absolute;
  bottom: calc(100% + ${SPACING_S});
  left: 50%;
  transform: translateX(-50%);
  padding: ${SPACING_S};
  border-radius: ${BORDER_RADIUS_SMALL};
  background: ${COLOR_WHITE};
  color: ${COLOR_BLACK};
  ${TYPE_SIZE_SANS_SMALL}
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  width: ${gridGutters(8)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(4)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(3)};
  }
`;
