import {Block, Media, RichText} from '@/content/cms/types';

import {BlockProps} from '..';
import JourneyIntro from './JourneyIntro';

export interface BlockJourneyIntroProps extends Block {
  title: string;
  subtitle: RichText;
  introCopy: RichText;
  mainVideo: Media;
  secondaryVideo: Media;
}

const BlockJourneyIntro = ({block}: BlockProps) => {
  const blockJourneyIntro = block as BlockJourneyIntroProps;
  return (
    <JourneyIntro
      title={blockJourneyIntro.title}
      subtitle={blockJourneyIntro.subtitle}
      introCopy={blockJourneyIntro.introCopy}
      mainVideo={blockJourneyIntro.mainVideo}
      secondaryVideo={blockJourneyIntro.secondaryVideo}
    />
  );
};

export default BlockJourneyIntro;
