import React from 'react';

import RichTextChunk from '@/components/RichTextChunk';
import {Block as ContentfulBlock, RichText} from '@/content/cms/types';

import {BlockProps} from '..';

export interface BlockRichTextProps extends ContentfulBlock {
  text: RichText;
}

const BlockRichText = ({block}: BlockProps) => {
  const blockRichText = block as BlockRichTextProps;
  return <RichTextChunk richText={blockRichText.text} />;
};

export default BlockRichText;
