import {useState} from 'react';

import {AnimatePresence} from 'framer-motion';

import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {USING_MOUSE} from '@/theme/mediaQueries';
import {TRANSITION_SPEED_REGULAR} from '@/theme/transitions';

import {PopupWrapper, StyledPopup} from './styles';

interface Props {
  text?: string;
}

const Popup: React.FC<Props> = ({text, children}) => {
  const [hovering, setHovering] = useState(false);
  const usingMouse = useClientMediaQuery(USING_MOUSE);

  if (!text || !usingMouse) return <>{children}</>;

  return (
    <PopupWrapper
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setHovering(false)}
    >
      <>
        <AnimatePresence>
          {hovering && (
            <StyledPopup
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: TRANSITION_SPEED_REGULAR}}
            >
              {text}
            </StyledPopup>
          )}
        </AnimatePresence>
        {children}
      </>
    </PopupWrapper>
  );
};

export default Popup;
