import {eventClickSourceLink} from '@/config/tracking';
import {Link} from '@/content/cms/types';
import {pxToRem} from '@/theme/grid';

import {SourceWrapper, StyledCopy, Content, StyledLink} from './styles';

export interface Props {
  className?: string;
  link: Link;
}

const Source = ({className, link}: Props) => (
  <SourceWrapper className={className}>
    <StyledCopy width={pxToRem(12)} height={pxToRem(12)} />
    <Content>
      <StyledLink link={link} tracking={eventClickSourceLink(link.title)}>
        {link.description || link.title}
      </StyledLink>
    </Content>
  </SourceWrapper>
);

export default Source;
