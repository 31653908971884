import {JourneyLabelType} from '@/content/cms/types';
import {
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_WHITE,
} from '@/theme/colors';

import {
  Label,
  LabelContents,
  LabelCircle,
  LabelInnerCircle,
  LabelText,
  LabelTitle,
} from './styles';

interface JourneyLabelProps {
  label: JourneyLabelType;
}

const getLabelColor = (colorName?: string) => {
  switch (colorName) {
    case 'green':
      return COLOR_GREEN;
    case 'blue':
      return COLOR_BLUE;
    case 'orange':
      return COLOR_ORANGE;
    default:
      return COLOR_WHITE;
  }
};

const JourneyBlockLabel = ({label}: JourneyLabelProps) => {
  if (!label) return null;

  return (
    <Label
      $position="center"
      $inView={true}
      $color={getLabelColor(label.color)}
      $isChapter={true}
      $hideBorder={false}
    >
      <LabelContents>
        <LabelCircle>
          <LabelInnerCircle></LabelInnerCircle>
        </LabelCircle>
        <LabelText>
          {label.title && <LabelTitle>{label.title}</LabelTitle>}
        </LabelText>
      </LabelContents>
    </Label>
  );
};

export default JourneyBlockLabel;
