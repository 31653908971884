import Media from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import {User} from '@/content/cms/types';
import getAspectRatioVector2FromString from '@/utils/aspectRatio';

import {
  UserOrCompanyWrapper,
  MediaWrapper,
  StyledMedia,
  StyledHeading,
  StyledCopy,
} from './styles';

interface Props {
  className?: string;
  userOrCompany: User;
  /** Example: 1:1. */
  mediaAspectRatio?: string;
  centerMedia?: boolean;
  hasBlackBackground?: boolean;
}

const UserOrCompany = ({
  className,
  userOrCompany,
  mediaAspectRatio = '1:1',
  centerMedia,
}: Props) => (
  <UserOrCompanyWrapper className={className}>
    {userOrCompany.media &&
      (centerMedia ? (
        <MediaWrapper
          $aspectRatio={getAspectRatioVector2FromString(mediaAspectRatio)}
        >
          <StyledMedia
            media={userOrCompany.media}
            ignoreAspectRatio
            objectFit="contain"
          />
        </MediaWrapper>
      ) : (
        <Media media={userOrCompany.media} aspectRatio={mediaAspectRatio} />
      ))}
    <StyledHeading level={userOrCompany.headingLevel} animate>
      {userOrCompany.name}
    </StyledHeading>
    {userOrCompany.titleRichText && (
      <RichTextChunk richText={userOrCompany.titleRichText} />
    )}
    {userOrCompany.summary && <StyledCopy>{userOrCompany.summary}</StyledCopy>}
  </UserOrCompanyWrapper>
);

export default UserOrCompany;
