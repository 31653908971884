import {useEffect} from 'react';

import {useInView} from 'react-intersection-observer';

import RichTextChunk from '@/components/RichTextChunk';
import {ParagraphWithMedia} from '@/content/cms/types';

import {ParagraphWrapper, StyledHeading, StyledMedia, Eyebrow} from './styles';

interface ParagraphProps {
  className?: string;
  index: number;
  paragraph: ParagraphWithMedia;
  setVisibleImageIndex: (index: number) => void;
}

const Paragraph = ({
  className,
  index,
  paragraph,
  setVisibleImageIndex,
}: ParagraphProps) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView && paragraph.media) {
      setVisibleImageIndex(index);
    }
  }, [inView, index, paragraph.media, setVisibleImageIndex]);

  return (
    <ParagraphWrapper ref={inViewRef} className={className}>
      {paragraph.media && <StyledMedia media={paragraph.media} />}
      {paragraph.eyebrow && <Eyebrow level={6}>{paragraph.eyebrow}</Eyebrow>}
      <StyledHeading
        level={paragraph.headingLevel}
        $hasEyebrow={!!paragraph.eyebrow}
        animate
      >
        {paragraph.heading}
      </StyledHeading>
      <RichTextChunk richText={paragraph.copy} />
    </ParagraphWrapper>
  );
};

export default Paragraph;
