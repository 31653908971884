import {Block, Link as ContentfulLink} from '@/content/cms/types';

import {BlockProps} from '..';
import {
  BlockStatWrapper,
  StyledStat,
  StyledSource,
  StyledHeading,
} from './styles';

export interface BlockStatProps extends Block {
  stat: string;
  copy: string;
  source: ContentfulLink;
}

const BlockStat = ({block}: BlockProps) => {
  const blockStat = block as BlockStatProps;
  return (
    <BlockStatWrapper>
      <StyledStat level={2}>{blockStat.stat}</StyledStat>
      {blockStat.source && <StyledSource link={blockStat.source} />}
      <StyledHeading level={5}>{blockStat.copy}</StyledHeading>
    </BlockStatWrapper>
  );
};

export default BlockStat;
