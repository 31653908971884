import {css, keyframes} from 'styled-components';

import {onFocusOutline} from '@/theme/borders';
import {COLOR_BLACK} from '@/theme/colors';
import {
  TRANSITION_EASE_OUT,
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';

const animateLine = keyframes`
  to { background-size: 100% 1px, 0 1px; }
`;

export const LINK_BASE = css`
  background: linear-gradient(${COLOR_BLACK}, ${COLOR_BLACK});
  background-size: 100% 1px, 0 1px;

  /* Left to right */
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;

  &:hover {
    background-size: 0 1px, 100% 1px;

    /* Right to left */
    background-position: 0 100%, 100% 100%;

    /**
     * Animation works by animating out background-size, then swapping
     * background-position on end, followed by a keyframes animation that
     * animates line back in on transition finished.
     */
    transition: background-position 0s ${TRANSITION_SPEED_REGULAR}s,
      background-size ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_OUT};
    animation: ${animateLine} ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON}
      ${TRANSITION_SPEED_REGULAR}s forwards;
  }

  ${onFocusOutline()}

  /* Reset default link styles */
  color: inherit;
  text-decoration: none;
`;
