import {motion} from 'framer-motion';
import YouTubePlayer from 'react-player/youtube';
import styled from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {COLOR_BLACK, COLOR_BLACK_10, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {aspectRatio} from '@/theme/mediaQueries';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {ALLOW_CLICKS, PREVENT_CLICKS} from '@/theme/pointerEvents';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XXL_WITHOUT_MIN,
} from '@/theme/spacings';
import {
  TYPE_SIZE_HEADER_6,
  TYPE_SIZE_SANS_REGULAR,
  TYPE_WEIGHT_BOLD,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

export const YouTubeVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: block;
  }
`;

export const StyledYouTubePlayer = styled(YouTubePlayer)`
  border-radius: ${BORDER_RADIUS_SMALL};
  overflow: hidden;
`;

export const MediaWrapper = styled(motion.div)`
  width: 100%;
`;

export const VideoWrapper = styled.div`
  ${aspectRatio({x: 16, y: 9})};
  background: ${COLOR_BLACK};
  border-radius: ${BORDER_RADIUS_SMALL};
  overflow: hidden;
  position: relative;

  /* Fit content to aspect ratio */
  > ${StyledYouTubePlayer}, > ${MediaWrapper} {
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  $hasCaptionAbove?: boolean;
}

export const InfoWrapper = styled(motion.div)<Props>`
  margin-top: ${({$hasCaptionAbove}) =>
    $hasCaptionAbove ? SPACING_S : SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    position: absolute;
    margin: ${SPACING_XXL_WITHOUT_MIN} 0 0 ${gridGutters(1)};
    color: ${COLOR_WHITE};
    z-index: ${Z_INDEX_CONTENT_OVERLAY};
    top: 0;
    ${PREVENT_CLICKS}
  }
`;

export const IndexCopy = styled.span`
  display: block;
  ${TYPE_SIZE_SANS_REGULAR}
  margin-bottom: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    ${TYPE_SIZE_HEADER_6}
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: ${SPACING_L};
  }
`;

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    ${ALLOW_CLICKS}
  }
`;

export const StyledCopy = styled(Copy)`
  margin: 0 0 0 ${SPACING_S};
  ${TYPE_WEIGHT_BOLD};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    color: ${COLOR_WHITE};
  }
`;

export const Center = styled(motion.span)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Caption = styled(Copy)`
  margin: ${SPACING_S} 0 0;
  ${TYPE_WEIGHT_REGULAR}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(8)};
  }
`;

export const Shadow = styled.div`
  display: none;
  @media ${QUERY_GREATER_THAN_MOBILE} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Z_INDEX_CONTENT_OVERLAY};
    background: ${COLOR_BLACK_10};
    display: block;
    ${PREVENT_CLICKS}
  }
`;
