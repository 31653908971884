import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import {MEDIA_WRAPPER_CLASS} from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import Source from '@/components/Source';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
  USING_MOUSE,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XL,
  SPACING_XS,
  SPACING_XXL,
  SPACING_XXL_NEGATIVE,
} from '@/theme/spacings';
import {TYPE_WEIGHT_REGULAR} from '@/theme/type';

interface WrapperProps {
  $hasGutters: boolean;
  $hasBlackBackground: boolean;
}

export const BlockColumnsWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XXL};
  }

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  ${({$hasGutters}) =>
    $hasGutters &&
    css`
      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-right: ${gridGutters(1)};
        margin-left: ${gridGutters(1)};
      }
    `};
`;

interface Props {
  $hasSource?: boolean;
}

export const StyledHeading = styled(Heading)<Props>`
  width: 100%;
  margin-top: 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(13)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(16)};
  }

  ${({$hasSource}) =>
    !$hasSource &&
    css`
      margin-bottom: ${SPACING_S};

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-bottom: ${SPACING_L};
      }
    `}
`;

const COPY_BASE = css`
  width: 100%;
  margin: ${SPACING_XS} 0 ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_XS} 0 ${SPACING_L};
    width: ${gridGutters(13)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(16)};
  }
`;

export const StyledSource = styled(Source)`
  ${COPY_BASE}
`;

export const StyledCopy = styled(Copy)`
  ${COPY_BASE}
  ${TYPE_WEIGHT_REGULAR}
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    gap: ${gridGutters(1)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    gap: ${gridGutters(2)};
  }
`;

interface LeftColumnProps {
  $onlyContainsMedia: boolean;
  $onlyColumn: boolean;
  $hasBlackBackground: boolean;
  $hasHeading?: boolean;
}

export const LeftColumn = styled(RichTextChunk)<LeftColumnProps>`
  width: 100%;

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    > * {
      margin-left: auto;
      width: ${gridGutters(6)};
    }
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    > * {
      margin-right: 0;
      width: ${gridGutters(7)};
    }
  }

  &:is(div) {
    ${({$onlyColumn}) =>
      $onlyColumn
        ? css`
            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-right: ${gridGutters(7)};
            }

            @media ${QUERY_GREATER_THAN_TABLET} {
              margin-right: ${gridGutters(9)};
            }
          `
        : css`
            margin-bottom: ${SPACING_M};

            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-bottom: 0;
            }
          `}
  }

  > .${MEDIA_WRAPPER_CLASS} {
    ${({$onlyContainsMedia}) =>
      $onlyContainsMedia
        ? css`
            width: 100%;

            @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
              margin-top: ${SPACING_XXL_NEGATIVE};
            }
          `
        : css`
            margin-top: ${SPACING_M};

            @media ${QUERY_GREATER_THAN_MOBILE} {
              width: calc(100% - ${gridGutters(1)});
              margin: ${SPACING_L} auto 0 0;
            }

            @media ${QUERY_GREATER_THAN_TABLET} {
              margin-top: ${SPACING_XL};
            }
          `}
  }

  ${({$hasHeading}) =>
    !$hasHeading &&
    css`
      > *:first-child {
        margin-top: 0;
      }
    `}
`;

interface RightColumnProps {
  $leftColumnOnlyContainsMedia: boolean;
  $hasBlackBackground: boolean;
  $hasHeading?: boolean;
}

export const RightColumn = styled(RichTextChunk)<RightColumnProps>`
  width: 100%;

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  ${({$leftColumnOnlyContainsMedia}) =>
    css`
      @media ${QUERY_GREATER_THAN_MOBILE} {
        max-width: ${gridGutters($leftColumnOnlyContainsMedia ? 7 : 6)};
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        max-width: ${gridGutters($leftColumnOnlyContainsMedia ? 8 : 7)};
      }
    `}

  ${({$hasHeading}) =>
    !$hasHeading &&
    css`
      > *:first-child {
        margin-top: 0;
      }
    `}
`;
