import {motion} from 'framer-motion';
import styled from 'styled-components';

export const INITIAL_WORD_OPACITY = 0.2;

export const QuoteTextItemWrapper = styled(motion.blockquote)`
  margin: 0;
`;

export const WordWrapper = styled(motion.span)`
  position: relative;
`;

export const Word = styled(motion.span)`
  opacity: ${INITIAL_WORD_OPACITY};
`;
