import styled from 'styled-components';

import Copy from '@/components/Copy';
import {COLOR_DARK_GREY} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_TABLET} from '@/theme/mediaQueries';
import {SPACING_S, SPACING_XXS} from '@/theme/spacings';
import {TYPE_SIZE_SANS_SMALL, TYPE_WEIGHT_REGULAR} from '@/theme/type';

interface Props {
  $showOnDesktop?: boolean;
}

export const ChartSourcesWrapper = styled.div<Props>`
  color: ${COLOR_DARK_GREY};
  display: ${({$showOnDesktop}) => ($showOnDesktop ? 'none' : 'block')};

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: ${({$showOnDesktop}) => ($showOnDesktop ? 'block' : 'none')};
  }
`;

export const StyledCopy = styled(Copy)`
  margin-bottom: ${SPACING_XXS};
`;

export const SourcesWrapper = styled.ol`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: ${SPACING_S};
  row-gap: ${SPACING_XXS};
`;

export const Source = styled.li`
  ${TYPE_SIZE_SANS_SMALL}
  ${TYPE_WEIGHT_REGULAR}
  margin: 0;

  @media ${QUERY_GREATER_THAN_TABLET} {
    max-width: ${gridGutters(4)};
  }
`;
