import styled from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_M, SPACING_L} from '@/theme/spacings';

export const CarouselHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: ${SPACING_L};
    max-width: ${gridGutters(11)};
  }
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0;
`;

export const StyledCopy = styled(Copy)`
  margin-top: ${SPACING_M};
`;
