import {useEffect} from 'react';

import {useLocomotiveScroll} from 'react-locomotive-scroll';

import JourneyElement from '@/components/JourneyElement';
import {useLocomotiveElementConfig} from '@/config/locomotive';
import {Block, TimelineElement} from '@/content/cms/types';
import {useNav} from '@/contexts/nav';

import {BlockProps} from '..';
import {BlockJourneyTimelineWrapper} from './styles';

const ID = 'journey-wrapper';

interface TimelineElementsCollection {
  items: TimelineElement[];
}

interface BlockJourneyTimelineProps extends Block {
  timelineElementsCollection: TimelineElementsCollection;
}

const BlockJourneyTimeline = ({block}: BlockProps) => {
  const journeyTimeline = block as BlockJourneyTimelineProps;
  const {setSubNavShowing, setChapterTitles} = useNav();
  const {scroll} = useLocomotiveScroll();

  const locomotiveProps = useLocomotiveElementConfig({
    id: ID,
    call: ID,
    offset: '96%, 10%',
    repeat: true,
  });

  useEffect(() => {
    if (!scroll) return;

    scroll.on('call', (call: string) => {
      switch (call) {
        case ID:
          setSubNavShowing(true);
          break;
      }
    });
    return () => setSubNavShowing(false);
  }, [scroll, setSubNavShowing]);

  useEffect(() => {
    setChapterTitles(
      journeyTimeline.timelineElementsCollection.items
        .filter((section) => !!section.era)
        .map((chapter) => {
          return {
            title: chapter.navigationTitle ? chapter.navigationTitle : '',
            era: chapter.era ? chapter.era : '',
            chapter: chapter.chapter,
          };
        }),
    );
  }, [journeyTimeline.timelineElementsCollection.items, setChapterTitles]);

  return (
    <BlockJourneyTimelineWrapper {...locomotiveProps}>
      {journeyTimeline.timelineElementsCollection.items.map(
        (element, index) => (
          <JourneyElement key={index} sectionIndex={index} section={element} />
        ),
      )}
    </BlockJourneyTimelineWrapper>
  );
};

export default BlockJourneyTimeline;
