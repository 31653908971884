import {motion} from 'framer-motion';
import styled from 'styled-components';

import Heading from '@/components/Heading';
import Media from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import {COLOR_BLACK_20} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XL,
  SPACING_XL_NEGATIVE,
  SPACING_XXL,
} from '@/theme/spacings';
import {Z_INDEX_CONTENT, Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

export const JourneyIntroWrapper = styled(motion.div)`
  opacity: 0;

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-areas: 'main';

    /* !important used to override PageLayout.tsx removing top element margin */
    margin-top: ${SPACING_XL_NEGATIVE} !important;
  }
`;

export const MediaWrapper = styled.div`
  max-height: 100vh;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-area: main;
  }
`;

export const StyledHeroHeading = styled(Heading)`
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: 0;
  }
`;

export const StyledIntroCopyHeading = styled(Heading)`
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XL};
  }
`;

export const IntroCopy = styled(RichTextChunk)``;

export const MediaColumn = styled(motion.div)`
  display: flex;

  @media ${QUERY_GREATER_THAN_TABLET} {
    padding-top: ${SPACING_XXL};
  }
`;

export const CopyWrapper = styled(motion.div)`
  display: flex;
  z-index: ${Z_INDEX_CONTENT};
  width: 100%;
  margin-bottom: ${SPACING_XL};

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-bottom: ${SPACING_XXL};
    justify-content: flex-end;
    grid-area: main;
    padding-top: ${SPACING_XXL};
  }
`;

export const CopyColumn = styled.div`
  width: 100%;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(15)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(12)};
  }
`;

export const IntroMedia = styled(Media)`
  width: ${gridGutters(4.8)};
  filter: drop-shadow(
    ${pxToRem(-20)} ${pxToRem(20)} ${pxToRem(24)} ${COLOR_BLACK_20}
  );
`;

export const MediaItemWrapper = styled(motion.div)`
  transform-origin: top;
`;

export const HalfWidthColumn = styled.div`
  ${IntroCopy}:first-child {
    margin-top: ${SPACING_S};
  }

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(8)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(7)};
  }
`;

interface ScrollingWrapperProps {
  $isMainVideo?: boolean;
}

export const ScrollingWrapper = styled.div<ScrollingWrapperProps>`
  z-index: ${({$isMainVideo}) =>
    $isMainVideo ? Z_INDEX_CONTENT_OVERLAY : Z_INDEX_CONTENT};
`;
