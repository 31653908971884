import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import TextReveal from '@/components/TextReveal';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
  USING_MOUSE,
  USING_TOUCH,
} from '@/theme/mediaQueries';
import {PREVENT_CLICKS} from '@/theme/pointerEvents';
import {SPACING_L, SPACING_M, SPACING_S, SPACING_XL} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_SLOTH,
} from '@/theme/transitions';
import {
  TYPE_FONT_SERIF,
  TYPE_SIZE_HEADER_5,
  TYPE_SIZE_LARGE,
  TYPE_SIZE_LARGE_LINE_HEIGHT_DESKTOP,
  TYPE_SIZE_LARGE_LINE_HEIGHT_MOBILE,
  TYPE_SIZE_LARGE_LINE_HEIGHT_TABLET,
  TYPE_WEIGHT_BOLD,
} from '@/theme/type';

import ChartSources from './ChartSources';

export const LineChartWrapper = styled.div`
  display: grid;
  grid-template-areas: 'heading' 'main';
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_XL} ${gridGutters(1)} 0;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    grid-template-areas: 'main';
  }
`;

export const Track = styled.div`
  display: none;
  height: 250vh;

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    grid-area: main;
    display: block;
  }
`;

interface HeadingProps {
  $showOnDesktop?: boolean;
}

export const StyledHeading = styled(Heading)<HeadingProps>`
  margin-top: 0;
  display: ${({$showOnDesktop}) => ($showOnDesktop ? 'none' : 'initial')};
  grid-area: heading;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(14)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: ${({$showOnDesktop}) => ($showOnDesktop ? 'initial' : 'none')};
    width: ${gridGutters(16)};
    padding-top: ${SPACING_L};
    place-self: end;
  }
`;

export const Content = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${QUERY_GREATER_THAN_TABLET} {
    position: relative;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    height: 100vh;
  }
`;

export const StatsAndSourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    position: absolute;
    width: ${gridGutters(8)};
    padding-top: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(8)};
    bottom: 0;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    position: initial;
    padding-bottom: ${SPACING_L};
    height: 100%;
  }
`;

export const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: ${SPACING_S};
  padding-bottom: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-bottom: 0;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    padding-bottom: ${SPACING_L};
    justify-content: center;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_TOUCH} {
    padding-bottom: ${SPACING_XL};
  }
`;

export const StyledTextReveal = styled(TextReveal)`
  ${TYPE_SIZE_HEADER_5}
  ${TYPE_WEIGHT_BOLD}
`;

export const StyledChartSources = styled(ChartSources)`
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    margin-top: 0;
  }
`;

export const Graphic = styled.div`
  display: flex;
  flex-direction: column;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-left: auto;
  }

  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    position: absolute;
    bottom: ${SPACING_L};
    right: 0;
  }
`;

export const TooltipWrapper = styled.div`
  display: none;
  position: absolute;
  height: 100%;
  ${PREVENT_CLICKS}

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: block;
  }
`;

interface CircleGroupProps {
  $active: boolean;
}

export const CircleGroup = styled(motion.g)<CircleGroupProps>`
  @media ${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} {
    ${({$active}) =>
      $active &&
      css`
        &:hover {
          cursor: pointer;
        }
      `}
  }
`;

export const HiddenHeading = styled(Heading)`
  visibility: hidden;
`;

const PATH_BASE = css`
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5px;
`;

export const LinePath = styled.path`
  ${PATH_BASE}
`;

interface MaskPathProps {
  $animate?: boolean;
}

export const MaskPath = styled.path<MaskPathProps>`
  ${PATH_BASE}

  ${({$animate}) =>
    $animate &&
    css`
      transition: stroke-dashoffset ${TRANSITION_SPEED_SLOTH}s
        ${TRANSITION_EASE_CUBIC};
    `}
`;

export const YLinesGroup = styled(motion.g)`
  transform-origin: 100% 100% !important;
`;

export const YRect = styled.rect`
  width: 1px;
  opacity: 0.1;
`;

export const MaskCircle = styled(motion.circle)`
  r: 24px;
`;

export const OuterCircle = styled(motion.circle)`
  r: 14px;
  stroke-width: 3px;
`;

export const InnerCircle = styled(motion.circle)`
  r: 8px;
`;

export const StyledSvg = styled.svg`
  @media ${QUERY_GREATER_THAN_MOBILE} {
    height: 60vh;
    max-width: ${gridGutters(14)};
  }
`;

export const Axis = styled.div`
  position: relative;
  width: 100%;
  height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_MOBILE};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_TABLET};
    margin-left: auto;
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin: 0 auto;
    height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_DESKTOP};
  }
`;

export const AxisText = styled.div`
  ${TYPE_SIZE_LARGE}
  ${TYPE_FONT_SERIF}
  position: absolute;
  transform: translateX(-50%);
`;
