import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import Media from '@/components/Media';
import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {COLOR_WHITE} from '@/theme/colors';
import {aspectRatio} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_XS} from '@/theme/spacings';
import Vector2 from '@/utils/math/Vector2';

export const UserOrCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface Props {
  $aspectRatio?: Vector2;
}

export const StyledMedia = styled(Media)`
  border-radius: 0;
`;

export const MediaWrapper = styled.div<Props>`
  background: ${COLOR_WHITE};
  border-radius: ${BORDER_RADIUS_SMALL};

  ${({$aspectRatio}) =>
    $aspectRatio &&
    css`
      ${aspectRatio($aspectRatio)};
      position: relative;

      ${StyledMedia} {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: ${SPACING_L};
      }
    `};
`;

export const StyledHeading = styled(Heading)`
  margin: ${SPACING_M} 0 ${SPACING_XS};
`;

export const StyledCopy = styled(Copy)`
  margin: ${SPACING_XS} 0 0;
`;
