import {useEffect, useRef} from 'react';

import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {useLocomotiveElementConfig} from '@/config/locomotive';
import {Block, Media} from '@/content/cms/types';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {QUERY_GREATER_THAN_MOBILE, USING_MOUSE} from '@/theme/mediaQueries';

import {BlockProps} from '..';
import {
  BlockStickyMediaContentWrapper,
  ContentWrapper,
  Track,
  StickyContent,
  StyledHeading,
  StyledCopy,
  StyledFullWidthMedia,
  Shadow,
} from './styles';

export interface BlockStickyMediaContentProps extends Block {
  media: Media;
  title: string;
  copy: string;
  id: string;
}

const BlockStickyMediaContent = ({block}: BlockProps) => {
  const blockStickyMediaContent = block as BlockStickyMediaContentProps;

  const trackId = `${blockStickyMediaContent.id}-track`;
  const stickyParams = useLocomotiveElementConfig({
    sticky: true,
    id: trackId,
    target: `#${trackId}`,
  });

  const {scroll} = useLocomotiveScroll();
  const contentRef = useRef<HTMLDivElement>(null);
  const shadowRef = useRef<HTMLDivElement>(null);
  const tabletAndUsingMouse = useClientMediaQuery(
    `${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE}`,
  );

  useEffect(() => {
    if (!scroll) return;

    scroll.on(
      'scroll',
      (args: {currentElements: {[x: string]: {progress: number}}}) => {
        if (
          typeof args.currentElements[trackId] === 'object' &&
          contentRef.current &&
          shadowRef.current
        ) {
          const progress = args.currentElements[trackId].progress;
          const progressInverted = Math.abs(progress - 1);
          contentRef.current.style.transform = tabletAndUsingMouse
            ? `translateY(${progressInverted * 100}vh)`
            : '';
          shadowRef.current.style.opacity = tabletAndUsingMouse
            ? `${progress}`
            : '';
        }
      },
    );
  }, [scroll, tabletAndUsingMouse, trackId]);

  return (
    <BlockStickyMediaContentWrapper>
      <Track id={trackId} />
      <StickyContent {...stickyParams}>
        <StyledFullWidthMedia
          media={blockStickyMediaContent.media}
          id={blockStickyMediaContent.id}
          ignoreAspectRatio
        >
          <Shadow ref={shadowRef} />
          <ContentWrapper ref={contentRef}>
            <StyledHeading level={3}>
              {blockStickyMediaContent.title}
            </StyledHeading>
            <StyledCopy serifSize="large">
              {blockStickyMediaContent.copy}
            </StyledCopy>
          </ContentWrapper>
        </StyledFullWidthMedia>
      </StickyContent>
    </BlockStickyMediaContentWrapper>
  );
};

export default BlockStickyMediaContent;
