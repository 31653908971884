import styled from 'styled-components';

import {COLOR_ALMOST_BLACK, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';

export const BlockJourneyTimelineWrapper = styled.div`
  background-color: ${COLOR_ALMOST_BLACK};
  height: 100%;
  position: relative;
  color: ${COLOR_WHITE};
  padding: 0 ${gridGutters(1)};
  margin: 0 -${gridGutters(1)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding: 0 ${gridGutters(2)};
    margin: 0 -${gridGutters(1)};
  }
`;

export const SVGContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
