import {useCallback, useEffect, useRef, useState} from 'react';

import {line, curveBundle} from 'd3-shape';
import {useInView} from 'react-intersection-observer';

import JourneyLabel from '@/components/JourneyLabel';
import {TimelineElement} from '@/content/cms/types';
import {useNav} from '@/contexts/nav';
import {COLOR_WHITE} from '@/theme/colors';
import debounce from '@/utils/debounce';
import {IS_MOBILE} from '@/utils/environment';
import Vector2 from '@/utils/math/Vector2';

export type LinePosition = 'left' | 'right' | 'center';

import {
  Content,
  JourneySectionWrapper,
  JourneyHeading,
  StyledMedia,
  StyledMobileMedia,
  JourneySpacer,
  StyledRichTextChunk,
  ContentWrapper,
  RichTextChunkWrapper,
  CanvasWrapper,
  Caption,
} from './styles';

interface Props {
  section: TimelineElement;
  sectionIndex: number;
}

const getXOffset = (position: LinePosition) =>
  position === 'left' ? 1 : position === 'right' ? -2 : 0;

const generatePoints = (
  direction: {
    from: LinePosition;
    to: LinePosition;
  },
  bounds: Vector2,
  mobileSize: boolean,
) => {
  const {from, to} = direction;
  const left = 0;
  const right = 1;
  const center = 0.5;

  const fromPos =
    (from === 'left' ? left : from === 'right' ? right : center) * bounds.x;
  const toPos =
    (to === 'left' ? left : to === 'right' ? right : center) * bounds.x;

  const xOffsetFrom = getXOffset(from);
  const xOffsetTo = getXOffset(to);
  const yOffset = mobileSize ? 0.1 : 0;

  const origin = [fromPos + xOffsetFrom, 0];
  const midPoint = [fromPos + xOffsetFrom, (0.5 + yOffset) * bounds.y];
  const curveStart = [fromPos + xOffsetFrom, (0.7 + yOffset) * bounds.y];
  const curveEnd = [toPos + xOffsetTo, (0.73 + yOffset) * bounds.y];
  const destination = [toPos + xOffsetTo, 1 * bounds.y];

  return [origin, midPoint, curveStart, curveEnd, destination];
};

const getSectionDimensions = (container: HTMLDivElement) => ({
  totalWidth: container.offsetWidth,
  totalHeight: container.offsetHeight,
});

const JourneyElement = ({section, sectionIndex}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRendererRef = useRef<CanvasRenderingContext2D>();
  const sectionWrapperRef = useRef<HTMLDivElement>();
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const {setCurrentChapter, currentChapter, subNavScrolling} = useNav();
  const pointsRef = useRef<Path2D>();
  const [inViewRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [drawReady, setDrawReady] = useState(false);

  useEffect(() => {
    if (inView) {
      if (section.chapter) {
        if (!subNavScrolling.current) {
          setCurrentChapter(section.chapter);
        } else if (
          subNavScrolling.current &&
          section.chapter === currentChapter
        )
          setCurrentChapter(section.chapter);
      }
    }
  }, [
    inView,
    section.chapter,
    setCurrentChapter,
    subNavScrolling,
    currentChapter,
  ]);

  // Bind JSX element ref to intersection observer ref
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      sectionWrapperRef.current = node;
      inViewRef(node);
    },
    [inViewRef, sectionWrapperRef],
  );

  useEffect(() => {
    const draw = (ctx: CanvasRenderingContext2D, p: Path2D) => {
      if (!ctx || !p) return;

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      // Ensures that diagonal lineWidths same as horizontal/vertical
      ctx.translate(0.5, 0.5);
      ctx.imageSmoothingEnabled = true;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.setLineDash([1, 16]);
      ctx.strokeStyle = COLOR_WHITE;

      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.moveTo(0, 0);
      ctx.stroke(p);
    };

    if (
      canvasRendererRef.current &&
      pointsRef.current &&
      canvasRef.current &&
      drawReady
    ) {
      draw(canvasRendererRef.current, pointsRef.current);
    }
  }, [drawReady]);

  useEffect(() => {
    if (!canvasRef.current || !sectionWrapperRef.current) return;

    const lineGenerator = line();

    const createGraphic = () => {
      setDrawReady(false);
      if (!sectionWrapperRef.current || !canvasRef.current) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        canvasRendererRef.current = ctx;
      }
      const {totalWidth, totalHeight} = getSectionDimensions(
        sectionWrapperRef.current,
      );
      canvas.width = totalWidth;
      canvas.height = totalHeight;
      const points = generatePoints(
        // Need to handle `to` being undefined
        {from: section.line.from, to: section.line.to || section.line.from},
        {
          x: totalWidth,
          y: totalHeight,
        },
        IS_MOBILE,
      );

      const curveTension = 1;
      const bundleCurve = curveBundle.beta(curveTension);
      lineGenerator.curve(bundleCurve);

      const pathData = lineGenerator(points as [number, number][]);
      if (pathData) {
        pointsRef.current = new Path2D(pathData);
        setDrawReady(true);
      }
    };

    const handleResize = () => debounce(createGraphic, 50)();

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [section.line, sectionIndex]);

  const chapterProps = section.chapter
    ? {
        id: `journeyChapter${section.chapter}`,
      }
    : {};

  return (
    <JourneySectionWrapper ref={setRefs} {...chapterProps}>
      {section.line.to && <CanvasWrapper ref={canvasRef} />}
      <ContentWrapper
        ref={contentWrapperRef}
        $isChapterStart={!!section.chapter}
      >
        {section.year && <JourneyLabel section={section} revealLine={inView} />}
        {section.line && section.title ? (
          <Content
            $position={section.line.from}
            $chapterMarker={!!section.chapter}
            $hasBigGapLeft={section.label && !!section.label.color}
            $hasNoBodyCopy={section.title && !section.copy}
          >
            {section.image && (
              <>
                {section.imageMobileAlternative && (
                  <StyledMobileMedia media={section.imageMobileAlternative} />
                )}
                <StyledMedia
                  $placement={section.inline ? 'inline' : 'above'}
                  $hasBigGapLeft={section.label && !!section.label.color}
                  $hasDescription={section.image.description}
                  $mediaAlignRight={section.mediaAlignRight}
                  media={section.image}
                  $tiltMedia={section.tiltMedia}
                />
                {section.image.description && (
                  <Caption sansSize="small">
                    {section.image.description}
                  </Caption>
                )}
              </>
            )}
            {section.title && (
              <>
                <JourneyHeading
                  level={section.chapter ? 2 : 3}
                  richText={section.title}
                  $fullWidth={section.fullWidthTitle}
                  $level={section.chapter ? 2 : 3}
                  animate
                />
                <JourneyHeading
                  level={section.chapter ? 4 : 6}
                  richText={section.title}
                  $fullWidth={section.fullWidthTitle}
                  $level={section.chapter ? 4 : 6}
                  animate
                  $mobile
                />
              </>
            )}
            <RichTextChunkWrapper>
              {section.copy && <StyledRichTextChunk richText={section.copy} />}
            </RichTextChunkWrapper>
          </Content>
        ) : (
          <JourneySpacer />
        )}
      </ContentWrapper>
    </JourneySectionWrapper>
  );
};

export default JourneyElement;
