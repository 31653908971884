import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import BaseMedia from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import {BORDER_RADIUS_EXTRA_SMALL} from '@/theme/borders';
import {COLOR_DARK, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XL,
  SPACING_XXS,
  SPACING_XXL,
} from '@/theme/spacings';
import {TRANSITION_SPEED_FAST, TRANSITION_EASE_ON} from '@/theme/transitions';
import {
  TYPE_SIZE_SANS_REGULAR,
  TYPE_SIZE_SANS_SMALL,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';
import {Z_INDEX_CONTENT, Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

import {LinePosition} from './index';

export const JourneySectionWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const CanvasWrapper = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: ${Z_INDEX_CONTENT};
`;

interface ContentWrapperProps {
  $isChapterStart: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;

  ${({$isChapterStart}) =>
    $isChapterStart &&
    css`
      padding-top: ${SPACING_XL};
    `}
`;

interface ContentProps {
  $position: LinePosition;
  $chapterMarker: boolean;
  $hasBigGapLeft: boolean;
  $hasNoBodyCopy: boolean;
}

export const Content = styled.div<ContentProps>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};

  ${({$hasNoBodyCopy, $position, $hasBigGapLeft, $chapterMarker}) => {
    return !$hasNoBodyCopy
      ? css`
          padding: 0 ${$position === 'right' ? gridGutters(2) : 0}
            ${SPACING_XXL} ${$position === 'left' ? gridGutters(3) : 0};

          @media ${QUERY_GREATER_THAN_TABLET} {
            padding: 0 ${$hasBigGapLeft ? gridGutters(4) : gridGutters(2)}
              ${SPACING_XXL};

            ${$chapterMarker &&
            css`
              padding-right: 0;
              padding-left: ${gridGutters(4)};

              ${JourneyHeading} {
                max-width: 100%;
              }
            `}
          }
        `
      : css`
          padding: 0 0 ${SPACING_XXL}
            ${$position === 'left' ? gridGutters(3) : 0};
          width: ${gridGutters(12)};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            padding: 0 0 ${SPACING_XXL} 0;
            width: ${gridGutters(14)};
            margin-left: ${$position === 'left'
              ? gridGutters(2)
              : gridGutters(1)};
          }

          @media ${QUERY_GREATER_THAN_TABLET} {
            width: ${gridGutters(17)};
            margin-left: ${gridGutters(2)};
          }
        `;
  }}
`;

interface JourneyHeadingProps {
  $fullWidth?: boolean;
  $level: number;
  $mobile?: boolean;
}

export const JourneyHeading = styled(Heading)<JourneyHeadingProps>`
  margin: 0;

  display: ${({$mobile}) => ($mobile ? 'block' : 'none')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: ${({$mobile}) => ($mobile ? 'none' : 'block')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    max-width: 75%;

    ${({$fullWidth}) =>
      $fullWidth &&
      css`
        max-width: 100%;
        margin-right: 0;
      `}
  }
`;

export const RichTextChunkWrapper = styled.div``;

export const StyledRichTextChunk = styled(RichTextChunk)`
  color: ${COLOR_WHITE};
  margin-top: ${SPACING_M};
  margin-left: 0;

  display: block;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: flex;
    align-items: flex-start;
  }

  p:only-child {
    width: 100%;
    padding-left: 0;
    margin-top: 0;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      width: ${gridGutters(7)};
    }
  }

  p {
    width: 100%;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      width: ${gridGutters(6)};

      &:first-of-type {
        margin-right: ${gridGutters(1)};
      }
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      width: ${gridGutters(7)};

      &:first-of-type {
        margin-right: ${gridGutters(2)};
      }
    }
  }
`;

interface ImageProps {
  $placement?: 'inline' | 'above';
  $hasBigGapLeft: boolean;
  $hasDescription?: string;
  $mediaAlignRight?: boolean;
  $tiltMedia?: boolean;
}

export const StyledMobileMedia = styled(BaseMedia)`
  display: block;
  width: ${gridGutters(10)};
  margin-bottom: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: none;
  }
`;

export const StyledMedia = styled(BaseMedia)<ImageProps>`
  &&& {
    margin-bottom: ${SPACING_M};
    max-height: ${gridGutters(7)};

    ${({$mediaAlignRight}) =>
      $mediaAlignRight &&
      css`
        display: none;
      `}

    ${({$hasBigGapLeft}) =>
      $hasBigGapLeft &&
      css`
        max-width: ${gridGutters(5)};
      `}

    @media ${QUERY_GREATER_THAN_MOBILE} {
      max-width: ${gridGutters(8)};
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      ${({$hasBigGapLeft}) =>
        $hasBigGapLeft &&
        css`
          margin-left: ${gridGutters(6)};
        `}

      ${({$placement, $hasDescription}) =>
        $placement === 'inline'
          ? css`
              position: absolute;
              right: 0;
              width: ${gridGutters(5)};
            `
          : css`
              max-width: ${gridGutters(9)};
              margin-bottom: ${$hasDescription ? SPACING_S : SPACING_L};
            `}
    }

    @media ${QUERY_GREATER_THAN_MOBILE} {
      ${({$mediaAlignRight}) =>
        $mediaAlignRight &&
        css`
          display: block;
          position: absolute;
          right: ${gridGutters(-4)};
          top: 40%;
          width: ${gridGutters(7.5)};
          max-width: ${gridGutters(7.5)};
        `}
    }
  }

  ${({$tiltMedia}) =>
    $tiltMedia &&
    css`
      transform: rotate(-2deg);
    `};
`;

export const JourneySpacer = styled.div`
  width: 100%;
  min-height: ${SPACING_XL};
`;

interface LabelProps {
  $position: LinePosition;
  $inView: boolean;
  $color: string;
  $hideBorder: boolean;
}

export const Label = styled.div<LabelProps>`
  position: absolute;
  border-radius: ${BORDER_RADIUS_EXTRA_SMALL};
  box-shadow: 0 0 0 18px ${COLOR_DARK};
  background-color: ${COLOR_DARK};
  opacity: 1;

  ${({$position}) =>
    $position === 'left'
      ? css`
          left: 0;
        `
      : $position === 'right'
      ? css`
          right: 0;

          ${LabelCircle} {
            right: -13px;
            @media ${QUERY_GREATER_THAN_TABLET} {
              right: -16px;
            }
          }
        `
      : css`
          top: 0%;
          left: 50%;
          transform: translate(-50%);
          box-shadow: 0 0 0 18px ${COLOR_DARK};

          ${LabelContents} {
            &:before,
            &:after {
              opacity: 0;
            }
          }

          ${LabelCircle} {
            top: 0;
            left: 50%;
          }
        `};

  ${({$inView, $color, $hideBorder}) =>
    $inView &&
    css`
      opacity: 1;

      ${LabelText} {
        color: ${$color};
      }

      ${LabelCircle} {
        border: 1px solid ${$color};
        transition: border ${TRANSITION_SPEED_FAST * 3}s ${TRANSITION_EASE_ON}
          ${$hideBorder ? 0 : TRANSITION_SPEED_FAST * 3}s;
      }

      ${LabelInnerCircle} {
        background-color: ${$color};
      }

      ${LabelContents} {
        opacity: 1;
        &:before,
        &:after {
          width: 100%;
          height: 100%;
        }

        ${$hideBorder &&
        css`
          &:before,
          &:after {
            opacity: 0;
          }
        `};

        &:before {
          border-top-color: ${$color};
          border-right-color: ${$color};
          transition: width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON},
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST}s;
        }

        &:after {
          border-bottom-color: ${$color};
          border-left-color: ${$color};
          transition: border-color 0s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 3}s;
        }
      }
    `}
`;

export const LabelCircle = styled.span`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 0.6rem;
  transform: translate(-51%, -50%);
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_DARK};
  box-shadow: 0 0 0 4px ${COLOR_DARK};
  border: 1px solid transparent;

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: 16px;
    height: 16px;
  }
`;

export const LabelInnerCircle = styled.span`
  display: block;
  height: 2px;
  width: 2px;
  background-color: ${COLOR_WHITE};
  border-radius: 50%;

  @media ${QUERY_GREATER_THAN_TABLET} {
    height: 4px;
    width: 4px;
  }
`;

export const LabelContents = styled.div`
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px transparent;
  opacity: 0.4;

  &:before,
  &:after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    border: 1px solid transparent;
    width: 0;
    height: 0;
  }

  &:before {
    top: 0;
    left: 0;
  }

  &:after {
    bottom: 0;
    right: 0;
  }
`;

export const LabelText = styled.div`
  padding: ${SPACING_XXS} ${SPACING_S};
`;

export const LabelTitle = styled.div`
  ${TYPE_SIZE_SANS_REGULAR}
`;

export const LabelSubtitle = styled.div`
  ${TYPE_SIZE_SANS_SMALL}
`;

export const Caption = styled(Copy)`
  margin: ${SPACING_S} 0 0;
  ${TYPE_WEIGHT_REGULAR}
  margin-bottom: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(8)};
    margin-bottom: ${SPACING_M};
  }
`;
