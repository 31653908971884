import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import Link from '@/components/Link';
import {LINK_BASE} from '@/components/Link/styles';
import {COLOR_BLACK, COLOR_ORANGE} from '@/theme/colors';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S} from '@/theme/spacings';
import {TYPE_FONT_SERIF, TYPE_SIZE_REGULAR} from '@/theme/type';

export const StyledRichTextChunk = styled.div`
  > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledLink = styled(Link)`
  ${LINK_BASE}
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${SPACING_S};
`;

const LIST_BASE = css`
  margin: 0;
  padding: 0 0 0 ${SPACING_S};

  > li {
    /* Fix Safari adding extra spacing due to line-height */
    line-height: 0;

    &::marker {
      ${TYPE_FONT_SERIF};
      ${TYPE_SIZE_REGULAR};
      color: ${COLOR_ORANGE};
    }

    > * {
      width: auto;
    }
  }
`;

export const StyledList = styled.ul`
  list-style-type: square;
  ${LIST_BASE}
`;

export const StyledNumberedList = styled.ol`
  ${LIST_BASE}

  p {
    margin-left: ${SPACING_S};

    @media ${QUERY_GREATER_THAN_MOBILE} {
      margin-left: ${SPACING_L};
    }
  }

  > li {
    &::marker {
      color: ${COLOR_BLACK};
    }
  }
`;
