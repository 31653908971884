import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import BaseMedia from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_S, SPACING_XXL} from '@/theme/spacings';
import {
  TRANSITION_EASE_OUT,
  TRANSITION_SPEED_GLACIAL,
} from '@/theme/transitions';
import {
  TYPE_SIZE_HEADER_1,
  TYPE_WEIGHT_BOLD,
  TYPE_FONT_SANS,
} from '@/theme/type';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

interface ColorTheme {
  $hasBlackBackground: boolean;
  $inline?: boolean;
}

export const MilestoneWrapper = styled.div<ColorTheme>`
  margin: 0;
  margin-top: ${SPACING_XXL};
  position: relative;

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${pxToRem(200)};
  }
`;

interface InlineProps {
  $inline?: boolean;
  $mobile?: boolean;
  $carVisible?: boolean;
}

export const CopyWrapper = styled.div<InlineProps>`
  width: ${gridGutters(11)};

  ${({$carVisible}) =>
    $carVisible &&
    css`
      ${StyledMedia} {
        transform: translateX(0);
      }
    `};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: initial;
    ${({$inline}) =>
      $inline &&
      css`
        display: flex;
        flex-direction: row-reverse;
      `}
  }
`;

export const Year = styled.span`
  position: absolute;
  opacity: 0.1;
  left: ${gridGutters(-2.5)};
  top: ${gridGutters(-2)};

  ${TYPE_FONT_SANS}
  ${TYPE_SIZE_HEADER_1};
  ${TYPE_WEIGHT_BOLD}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    left: ${gridGutters(-4)};
  }
`;

export const StyledHeading = styled(Heading)<InlineProps>`
  width: ${gridGutters(11)};
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  margin-top: ${SPACING_M};

  display: ${({$mobile}) => ($mobile ? 'block' : 'none')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: ${({$mobile}) => ($mobile ? 'none' : 'block')};
    width: ${gridGutters(12)};
    margin-top: ${SPACING_L};

    ${({$inline}) =>
      !$inline &&
      css`
        width: ${gridGutters(11)};
      `}
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(16)};

    ${({$inline}) =>
      !$inline &&
      css`
        width: ${gridGutters(11)};
      `}
  }
`;

export const StyledRichTextChunk = styled(RichTextChunk)<ColorTheme>`
  margin-top: ${SPACING_S};
  display: block;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: flex;
    align-items: flex-start;
  }

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  p:only-child {
    width: 100%;
    padding-left: 0;
    margin-top: 0;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      width: ${gridGutters(7)};
    }
  }

  p {
    width: 100%;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      max-width: ${gridGutters(6)};

      &:first-of-type {
        margin-right: ${gridGutters(1)};
      }
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      max-width: ${gridGutters(7)};

      &:first-of-type {
        margin-right: ${gridGutters(2)};
      }
    }
  }
`;

interface MediaProps {
  $inline?: boolean;
  $isCarMedia?: boolean;
}

export const StyledMedia = styled(BaseMedia)<MediaProps>`
  display: block;
  height: fit-content;
  position: relative;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};

  ${({$isCarMedia}) =>
    $isCarMedia &&
    css`
      transition: transform ${TRANSITION_SPEED_GLACIAL}s ${TRANSITION_EASE_OUT};
      transform: translateX(calc(-100vw - ${gridGutters(12)}));
    `};

  ${({$inline}) =>
    $inline
      ? css`
          width: ${gridGutters(12)};
          max-width: ${gridGutters(12)};
          margin-right: ${gridGutters(1)};
        `
      : css`
          width: ${gridGutters(9)};
          max-width: ${gridGutters(9)};
        `}
`;
