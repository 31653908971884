import {TimelineElement} from '@/content/cms/types';
import {
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_WHITE,
} from '@/theme/colors';

import {
  Label,
  LabelContents,
  LabelContentsWrapper,
  LabelCircle,
  LabelInnerCircle,
  LabelText,
  LabelTitle,
  LabelSubtitle,
} from './styles';

interface JourneyLabelProps {
  section: TimelineElement;
  revealLine: boolean;
}

const getLabelColor = (colorName?: string) => {
  switch (colorName) {
    case 'green':
      return COLOR_GREEN;
    case 'blue':
      return COLOR_BLUE;
    case 'orange':
      return COLOR_ORANGE;
    default:
      return COLOR_WHITE;
  }
};

const JourneyLabel = ({section, revealLine}: JourneyLabelProps) => {
  if (!section || !section.label) return null;

  return (
    <Label
      $position={section.line.from}
      $inView={revealLine}
      $color={getLabelColor(section.label.color)}
      $hideBorder={!!!section?.label?.subTitle && !section.chapter}
      $isChapter={!!section.chapter}
    >
      <LabelContents>
        <LabelContentsWrapper $position={section.line.from}>
          <LabelCircle $position={section.line.from}>
            <LabelInnerCircle></LabelInnerCircle>
          </LabelCircle>
          <LabelText $position={section.line.from}>
            {section.label.title && (
              <LabelTitle>{section.label.title}</LabelTitle>
            )}
            {section.label.subTitle && (
              <LabelSubtitle>{section.label.subTitle}</LabelSubtitle>
            )}
          </LabelText>
        </LabelContentsWrapper>
      </LabelContents>
    </Label>
  );
};

export default JourneyLabel;
