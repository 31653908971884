import {useCallback, useEffect, useRef, useState} from 'react';

import {useAnimation} from 'framer-motion';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {ScrollProperties} from '@/components/LocomotiveScroll';
import {useLocomotiveElementConfig} from '@/config/locomotive';
import {useAnimationSettings} from '@/contexts/animationSettings';
import {useNav} from '@/contexts/nav';
import {gridGutters, pxToRem} from '@/theme/grid';
import {
  EASE_CUBIC,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_GLACIAL,
  TRANSITION_SPEED_SLOTH,
  TRANSITION_STAGGER_REGULAR,
} from '@/theme/transitions';
import {IS_TABLET} from '@/utils/environment';

import {BlockJourneyIntroProps} from '..';
import {
  JourneyIntroWrapper,
  MediaColumn,
  MediaWrapper,
  MediaItemWrapper,
  CopyColumn,
  HalfWidthColumn,
  IntroMedia,
  StyledHeroHeading,
  StyledIntroCopyHeading,
  IntroCopy,
  CopyWrapper,
  ScrollingWrapper,
} from './styles';

const WRAPPER_ID = 'journey-intro-wrapper';
const STICKY_ID = 'journey-intro-target';

const VIDEO_PROPS = {
  aspectRatio: '600:850',
  loop: false,
  animate: false,
  lazy: false,
  preload: true,
};

const JourneyIntro = ({
  title,
  subtitle,
  introCopy,
  mainVideo,
  secondaryVideo,
}: Omit<BlockJourneyIntroProps, '__typename'>) => {
  const mediaColumnRef = useRef<HTMLDivElement>(null);
  const copyWrapperRef = useRef<HTMLDivElement>(null);
  const mainMediaScroller = useRef<HTMLDivElement>(null);
  const secondaryMediaScroller = useRef<HTMLDivElement>(null);
  const [hasStickyBehaviour, setHasStickyBehaviour] = useState(true);
  const {setSubNavShowing} = useNav();
  const {enabled} = useAnimationSettings();

  const TRANSITION_CONFIG = {
    duration: enabled ? TRANSITION_SPEED_SLOTH : 0,
    ease: EASE_CUBIC,
  };

  const WRAPPER_VARIANTS = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: enabled ? TRANSITION_SPEED_FAST : 0,
      },
    },
  };

  const MAIN_MEDIA_VARIANTS = {
    initial: {
      translateX: gridGutters(IS_TABLET ? 2 : 5),
      translateY: '100vh',
      rotate: '1deg',
    },
    revealUp: {
      translateY: '0vh',
    },
    revealLeft: {
      translateX: gridGutters(0),
    },
    split: {
      translateX: gridGutters(IS_TABLET ? 3 : 2),
      scale: 1.15,
      rotate: '0deg',
    },
  };

  const SECONDARY_MEDIA_VARIANTS = {
    initial: {
      translateX: `calc(${gridGutters(IS_TABLET ? 3 : 5)} + ${pxToRem(
        -40,
        false,
      )})`,
      translateY: `calc(100vh + ${pxToRem(-70, false)})`,
      rotate: '-2deg',
    },
    revealUp: {
      translateY: `calc(0vh + ${pxToRem(-70, false)})`,
    },
    revealLeft: {
      translateX: `calc(${gridGutters(0)} + ${pxToRem(-40, false)})`,
    },
  };

  const TEXT_VARIANTS = {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const MEDIA_COLUMN_VARIANTS = {
    revealUp: {
      transition: {
        ...TRANSITION_CONFIG,
        staggerChildren: TRANSITION_STAGGER_REGULAR,
      },
    },
    revealLeft: {
      transition: {
        ...TRANSITION_CONFIG,
        delay: TRANSITION_SPEED_FAST,
        staggerChildren: TRANSITION_STAGGER_REGULAR,
      },
    },
    split: {
      transition: TRANSITION_CONFIG,
    },
  };

  // Framer motion controllers
  const wrapperControls = useAnimation();
  const mediaColumnControls = useAnimation();
  const textControls = useAnimation();

  // Animation progress refs
  const hasRevealed = useRef(false);
  const postRevealScroll = useRef(false);
  const scrollAmount = useRef(0);

  // Triggers reveals
  const [reveal, setReveal] = useState(false);

  const wrapperLocomotiveProps = useLocomotiveElementConfig({
    id: WRAPPER_ID,
    call: WRAPPER_ID,
    offset: '96%, 10%',
    repeat: true,
  });

  // Sticky media column
  const columnLocomotiveProps = useLocomotiveElementConfig({
    sticky: hasStickyBehaviour,
    id: STICKY_ID,
    target: `#${STICKY_ID}`,
  });

  const {scroll} = useLocomotiveScroll();

  useEffect(() => {
    if (!scroll) return;

    scroll.on('call', (call: string) => {
      switch (call) {
        case WRAPPER_ID:
          setSubNavShowing(false);
          break;
      }
    });
    return () => setSubNavShowing(false);
  }, [scroll, setSubNavShowing]);

  const sequence = useCallback(async () => {
    await textControls.set('initial');
    await mediaColumnControls.set('initial');
    await wrapperControls.set('visible');

    await mediaColumnControls.start('revealUp').then(() => {
      textControls.start('visible');
    });
    setTimeout(() => setReveal(true), TRANSITION_SPEED_FAST);
    await mediaColumnControls.start('revealLeft').then(() => {
      hasRevealed.current = true;
    });
  }, [mediaColumnControls, textControls, wrapperControls]);

  const handleResize = useCallback(() => {
    if (!postRevealScroll.current && !IS_TABLET) {
      setReveal(false);
      sequence();
    }
    setHasStickyBehaviour(!IS_TABLET);
  }, [sequence]);

  useEffect(() => {
    if (mediaColumnRef.current) {
      setHasStickyBehaviour(!IS_TABLET);
      sequence();

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [
    handleResize,
    mediaColumnControls,
    sequence,
    textControls,
    wrapperControls,
  ]);

  useEffect(() => {
    if (!scroll) return;

    scroll.on('scroll', ({scroll}: ScrollProperties) => {
      if (
        hasRevealed.current &&
        mainMediaScroller.current &&
        secondaryMediaScroller.current
      ) {
        const isForwards = scroll.y > scrollAmount.current;
        const isDesktop = !IS_TABLET;

        if (!postRevealScroll.current && isForwards) {
          if (isDesktop) {
            mediaColumnControls.start('split');
            postRevealScroll.current = true;
          }
        }
        if (postRevealScroll.current && isDesktop) {
          secondaryMediaScroller.current.style.transform = `translateY(-${scroll.y}px)`;

          if (scroll.y > window.innerHeight * 0.75) {
            secondaryMediaScroller.current.style.opacity = `0`;
          }
        }
      }
      scrollAmount.current = scroll.y;
    });
  }, [mediaColumnControls, hasStickyBehaviour, scroll]);

  return (
    <JourneyIntroWrapper
      variants={WRAPPER_VARIANTS}
      animate={wrapperControls}
      initial="initial"
      {...wrapperLocomotiveProps}
    >
      <MediaWrapper>
        <MediaColumn
          ref={mediaColumnRef}
          animate={mediaColumnControls}
          variants={MEDIA_COLUMN_VARIANTS}
          initial="initial"
          {...columnLocomotiveProps}
        >
          <ScrollingWrapper ref={mainMediaScroller} $isMainVideo>
            <MediaItemWrapper
              variants={MAIN_MEDIA_VARIANTS}
              transition={TRANSITION_CONFIG}
            >
              <IntroMedia media={mainVideo} {...VIDEO_PROPS} />
            </MediaItemWrapper>
          </ScrollingWrapper>
          <ScrollingWrapper ref={secondaryMediaScroller}>
            <MediaItemWrapper
              variants={SECONDARY_MEDIA_VARIANTS}
              transition={TRANSITION_CONFIG}
            >
              <IntroMedia media={secondaryVideo} {...VIDEO_PROPS} />
            </MediaItemWrapper>
          </ScrollingWrapper>
        </MediaColumn>
      </MediaWrapper>
      <CopyWrapper
        ref={copyWrapperRef}
        animate={textControls}
        variants={TEXT_VARIANTS}
        data-scroll
        id={STICKY_ID}
      >
        <CopyColumn>
          <StyledHeroHeading
            level={2}
            animate
            revealState={reveal ? 'animate' : 'initial'}
          >
            {title}
          </StyledHeroHeading>
          <HalfWidthColumn>
            <StyledIntroCopyHeading
              level={5}
              richText={subtitle}
              animate
              revealState={reveal ? 'animate' : 'initial'}
              revealDelay={TRANSITION_SPEED_SLOTH}
            />
            <IntroCopy
              richText={introCopy}
              animateCopy
              copyRevealState={reveal ? 'animate' : 'initial'}
              copyRevealDelay={TRANSITION_SPEED_GLACIAL}
            />
          </HalfWidthColumn>
        </CopyColumn>
      </CopyWrapper>
    </JourneyIntroWrapper>
  );
};

export default JourneyIntro;
