import Paragraph from '@/components/Blocks/BlockParagraphsWithMedia/Paragraph';
import {ParagraphWithMedia} from '@/content/cms/types';

import {ParagraphColumnWrapper} from './styles';

interface ParagraphColumnProps {
  paragraphs: ParagraphWithMedia[];
  setVisibleImageIndex: (index: number) => void;
}

const ParagraphColumn = ({
  paragraphs,
  setVisibleImageIndex,
}: ParagraphColumnProps) => {
  return (
    <ParagraphColumnWrapper>
      {paragraphs.map((paragraph, index) => (
        <Paragraph
          key={index}
          index={index}
          paragraph={paragraph}
          setVisibleImageIndex={setVisibleImageIndex}
        />
      ))}
    </ParagraphColumnWrapper>
  );
};

export default ParagraphColumn;
