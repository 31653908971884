import styled, {css} from 'styled-components';

import {LinePosition} from '@/components/JourneyElement';
import {BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL} from '@/theme/borders';
import {COLOR_ALMOST_BLACK, COLOR_WHITE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {TRANSITION_EASE_ON, TRANSITION_SPEED_FAST} from '@/theme/transitions';
import {TYPE_FONT_SANS, TYPE_SIZE_REGULAR} from '@/theme/type';

export const LabelContents = styled.div`
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px transparent;
  opacity: 0.4;
  transition: opacity 0.3s ease;

  &:before,
  &:after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    border: 1px solid transparent;
    width: 0;
    height: 0;
  }

  &:before {
    top: 0;
    left: 0;
  }

  &:after {
    bottom: 0;
    right: 0;
  }
`;

export const LabelCircle = styled.span`
  position: absolute;
  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_ALMOST_BLACK};
  box-shadow: 0 0 0 ${BORDER_RADIUS_SMALL} ${COLOR_ALMOST_BLACK};
  border: 1px solid transparent;
`;
interface LabelProps {
  $position: LinePosition;
  $inView: boolean;
  $color: string;
  $hideBorder: boolean;
  $isChapter: boolean;
}

export const Label = styled.div<LabelProps>`
  position: absolute;
  background-color: ${COLOR_ALMOST_BLACK};
  opacity: 1;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 0 ${BORDER_RADIUS_MEDIUM} ${COLOR_ALMOST_BLACK};

  ${({$isChapter}) =>
    $isChapter
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: block;
  }

  ${LabelContents} {
    &:before,
    &:after {
      opacity: 0;
    }
  }

  ${LabelCircle} {
    top: 0;
    left: 50%;
  }

  ${({$inView, $color, $hideBorder}) =>
    $inView &&
    css`
      opacity: 1;

      ${LabelText} {
        color: ${$color};
      }

      ${LabelCircle} {
        border: 1px solid ${$color};
        transition: border ${TRANSITION_SPEED_FAST * 3}s ${TRANSITION_EASE_ON}
          ${TRANSITION_SPEED_FAST * 3}s;
      }

      ${LabelInnerCircle} {
        background-color: ${$color};
      }

      ${LabelContents} {
        opacity: 1;
        &:before,
        &:after {
          width: 100%;
          height: 100%;
        }

        ${$hideBorder &&
        css`
          &:before,
          &:after {
            opacity: 0;
          }
        `};

        &:before {
          border-top-color: ${$color};
          border-right-color: ${$color};
          transition: width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON},
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST}s;
        }

        &:after {
          border-bottom-color: ${$color};
          border-left-color: ${$color};
          transition: border-color 0s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 3}s;
        }
      }
    `}
`;

export const LabelInnerCircle = styled.span`
  display: block;
  height: ${pxToRem(5)};
  width: ${pxToRem(5)};
  background-color: ${COLOR_WHITE};
  border-radius: 50%;
`;

export const LabelText = styled.div`
  padding-top: 25px;
  max-width: ${gridGutters(7)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(2)};
  }
`;

export const LabelTitle = styled.div`
  ${TYPE_FONT_SANS}
  ${TYPE_SIZE_REGULAR}
`;
