import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import QuoteTextItem from '@/components/QuoteTextItem';
import RichTextChunk from '@/components/RichTextChunk';
import {COLOR_ORANGE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_XXS,
  SPACING_M,
  SPACING_XL,
  SPACING_XS,
} from '@/theme/spacings';
import {
  TYPE_SIZE_HEADER_2,
  TYPE_SIZE_HEADER_4,
  TYPE_WEIGHT_BOLD,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';

const QUOTE_MARK_HEIGHT_DESKTOP = pxToRem(110);
const QUOTE_MARK_HEIGHT_TABLET = pxToRem(70);

export const BlockTeamQuoteWrapper = styled.div`
  display: flex;
  margin-top: ${SPACING_M};
  flex-direction: column-reverse;
  position: relative;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    margin-top: ${SPACING_L};
    padding: 0 ${gridGutters(1)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XL};
  }
`;

export const SourceWrapper = styled.div`
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(2.5)};
    margin: 0 ${gridGutters(0.5)} 0 0;
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(3)};
    margin-right: ${gridGutters(1)};
  }
`;

interface QuoteMarkProps {
  $isOpen?: boolean;
  $showOnDesktop?: boolean;
}

export const QuoteMark = styled.div<QuoteMarkProps>`
  ${TYPE_SIZE_HEADER_2}
  color: ${COLOR_ORANGE};

  ${({$isOpen, $showOnDesktop}) =>
    $isOpen
      ? css`
          ${$showOnDesktop
            ? css`
                display: none;

                @media ${QUERY_GREATER_THAN_MOBILE} {
                  display: flex;
                  height: ${QUOTE_MARK_HEIGHT_TABLET};
                }

                @media ${QUERY_GREATER_THAN_TABLET} {
                  display: flex;
                  height: ${QUOTE_MARK_HEIGHT_DESKTOP};
                }
              `
            : css`
                position: absolute;
                bottom: 100%;
                display: flex;

                @media ${QUERY_GREATER_THAN_MOBILE} {
                  display: none;
                }
              `}

          &:before {
            content: open-quote;
          }
        `
      : css`
          position: absolute;
          top: 100%;
          right: 0;
          display: flex;

          @media ${QUERY_GREATER_THAN_MOBILE} {
            right: initial;
            left: 100%;
          }

          &:after {
            content: close-quote;
          }
        `};
`;

export const QuoteName = styled(Heading)`
  margin: 0 0 ${SPACING_XXS};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: ${SPACING_XS};
  }
`;

export const QuoteRole = styled(RichTextChunk)`
  ${TYPE_WEIGHT_REGULAR};
  margin: 0;
`;

export const QuoteWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(10)};
    flex-direction: column;
    margin-top: ${QUOTE_MARK_HEIGHT_TABLET};
    margin-right: auto;
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(12)};
    margin-top: ${QUOTE_MARK_HEIGHT_DESKTOP};
  }
`;

export const Quote = styled(QuoteTextItem)`
  ${TYPE_SIZE_HEADER_4}
  ${TYPE_WEIGHT_BOLD}
  width: 100%;

  /* Remove default blockquote styles */
  margin: 0;
`;

export const MediaWrapper = styled.div`
  margin-left: auto;
  transform: rotate(-1.5deg);
  width: ${gridGutters(6)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(3)};
    position: absolute;
    right: 0;
    top: 0;
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(4)};
    position: initial;
  }
`;
