import React from 'react';

import {Block, MediaWithDescription} from '@/content/cms/types';

import {BlockProps} from '..';
import {StyledFullWidthMedia, Caption} from './styles';

export interface BlockMediaProps extends Block {
  media: MediaWithDescription;
  id: string;
  isAtTopOfPage?: boolean;
}

const BlockMedia = ({block}: BlockProps) => {
  const blockMedia = block as BlockMediaProps;
  return (
    <StyledFullWidthMedia
      media={blockMedia.media}
      id={blockMedia.id}
      lazy={blockMedia.isAtTopOfPage !== true}
      preload={blockMedia.isAtTopOfPage === true}
    >
      {blockMedia.media.description && (
        <Caption sansSize="small">{blockMedia.media.description}</Caption>
      )}
    </StyledFullWidthMedia>
  );
};

export default BlockMedia;
