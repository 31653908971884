import {useEffect} from 'react';

import {useInView} from 'react-intersection-observer';

import JourneyBlockLabel from '@/components/JourneyBlockLabel';
import Milestone from '@/components/Milestone';
import {JourneyLabelType, RichText} from '@/content/cms/types';
import {useNav} from '@/contexts/nav';

import {BlockProps} from '..';
import BlockUnknown from '../BlockUnknown';
import MilestoneColumns from './ColumnsWrapper';
import NavTrigger from './NavTrigger';
import {
  BlockMilestonesWrapper,
  StyledHeading,
  StyledRichTextChunk,
  BlocksWrapper,
  StyledBlockCarousel,
} from './styles';

interface MilestoneBlock {
  __typename: string;
  isFullWidthOnTheJourneyPage?: boolean;
}

interface MilestoneListCollection {
  items: MilestoneBlock[];
}

interface BlockMilestonesProps {
  heading: string;
  backgroundColor: boolean;
  subHeadingCopy?: RichText;
  textAlign: boolean;
  milestoneListCollection: MilestoneListCollection;
  label?: JourneyLabelType;
  navigationTitle?: string;
  era?: string;
  chapter: number;
}

interface Blocks {
  [key: string]: React.ComponentType<BlockProps>;
}

const availableBlocks = {
  BlockCarousel: StyledBlockCarousel,
  ComponentMilestone: Milestone,
  BlockColumns: MilestoneColumns,
} as unknown as Blocks;

const BlockMilestones = ({block}: BlockProps) => {
  const blockMilestones = block as unknown as BlockMilestonesProps;
  const {
    setCurrentChapter,
    currentChapter,
    chapterTitles,
    setChapterTitles,
    subNavScrolling,
    setSubNavShowing,
    setSubNavTheme,
  } = useNav();

  const [inViewRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  useEffect(() => {
    if (!inView) return;
    setSubNavShowing(true);
    setSubNavTheme(blockMilestones.backgroundColor ? 'dark' : 'light');

    if (!subNavScrolling.current) {
      setCurrentChapter(blockMilestones.chapter);
    } else if (
      subNavScrolling.current &&
      blockMilestones.chapter === currentChapter
    )
      setCurrentChapter(blockMilestones.chapter);
  }, [
    blockMilestones,
    currentChapter,
    inView,
    setCurrentChapter,
    subNavScrolling,
    setSubNavShowing,
    chapterTitles,
    setSubNavTheme,
  ]);

  useEffect(() => {
    if (
      !chapterTitles ||
      !chapterTitles.length ||
      !blockMilestones.navigationTitle
    )
      return;
    if (chapterTitles.some((e) => e.title === blockMilestones.navigationTitle))
      return;

    const newChapterTitles = [
      ...chapterTitles,
      {
        title: blockMilestones.navigationTitle,
        era: blockMilestones.era,
        chapter: blockMilestones.chapter,
      },
    ].sort((a, b) => (a.chapter > b.chapter ? 1 : -1));

    setChapterTitles(newChapterTitles);
  }, [
    chapterTitles,
    setChapterTitles,
    blockMilestones.navigationTitle,
    blockMilestones.era,
    blockMilestones.chapter,
  ]);

  const chapterProps = blockMilestones.chapter
    ? {
        id: `journeyChapter${blockMilestones.chapter}`,
      }
    : {};

  return (
    <div ref={inViewRef}>
      <BlockMilestonesWrapper
        $backgroundColor={blockMilestones.backgroundColor}
        {...chapterProps}
      >
        {blockMilestones.label && (
          <JourneyBlockLabel label={blockMilestones.label} />
        )}
        <StyledHeading
          $backgroundColor={blockMilestones.backgroundColor}
          level={2}
          animate
        >
          {blockMilestones.heading}
        </StyledHeading>
        <StyledHeading
          $backgroundColor={blockMilestones.backgroundColor}
          level={3}
          animate
          $mobile
        >
          {blockMilestones.heading}
        </StyledHeading>
        {blockMilestones.subHeadingCopy && (
          <StyledRichTextChunk
            $backgroundColor={blockMilestones.backgroundColor}
            richText={blockMilestones.subHeadingCopy}
          />
        )}
        {blockMilestones.milestoneListCollection.items.map((block, index) => {
          const BlockComponent =
            availableBlocks[block.__typename] || BlockUnknown;

          const isFullWidthCarousel =
            block.__typename === 'BlockCarousel' &&
            block.isFullWidthOnTheJourneyPage
              ? true
              : false;

          return (
            <BlocksWrapper
              key={index}
              $isFullWidthCarousel={isFullWidthCarousel}
            >
              <BlockComponent
                block={block}
                hasBlackBackground={blockMilestones.backgroundColor}
                level={6}
              />
            </BlocksWrapper>
          );
        })}
        <NavTrigger hasBlackBackground={blockMilestones.backgroundColor} />
      </BlockMilestonesWrapper>
    </div>
  );
};

export default BlockMilestones;
