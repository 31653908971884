import {useEffect, useState} from 'react';

import {SwiperSlide} from 'swiper/react';

import {CursorType} from '@/components/Cursor';
import {Block, User} from '@/content/cms/types';
import {useCursor} from '@/contexts/cursor';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';

import CarouselHeading from './CarouselHeading';
import {
  CarouselWrapper,
  SwiperWrapper,
  StyledSwiper,
  StyledUserOrCompany,
} from './styles';

import 'swiper/swiper.min.css';

export interface BlockCarouselProps extends Block {
  heading: string;
  copy?: string;
  contentCollection: {
    items: User[];
  };
  mediaAspectRatio?: '1:1' | '3:2';
  centerMedia?: boolean;
  hasGutters?: boolean;
  isFullWidthOnTheJourneyPage?: boolean;
}

interface BlockProps {
  block: Block;
  hasBlackBackground?: boolean;
  className?: string;
}

const BlockCarousel = ({
  className,
  block,
  hasBlackBackground = false,
}: BlockProps) => {
  const blockCarousel = block as BlockCarouselProps;
  const [slidesPerView, setSlidesPerView] = useState<number>(1.2);
  const [enabledCursor, setEnabledCursor] = useState<boolean>();

  const {setType} = useCursor();
  const cursorInteractions = {
    onMouseOver: () => enabledCursor && setType(CursorType.Drag),
    onMouseOut: () => enabledCursor && setType(CursorType.None),
  };

  const clientIsTablet = useClientMediaQuery(QUERY_GREATER_THAN_MOBILE);
  const clientIsDesktop = useClientMediaQuery(QUERY_GREATER_THAN_TABLET);

  useEffect(() => {
    const itemsLength = blockCarousel.contentCollection.items.length;

    // Desktop
    if (clientIsDesktop) {
      setSlidesPerView(blockCarousel.isFullWidthOnTheJourneyPage ? 3.35 : 3.15);
      setEnabledCursor(itemsLength > 3);
    }

    // Tablet
    else if (clientIsTablet) {
      setSlidesPerView(blockCarousel.isFullWidthOnTheJourneyPage ? 3.3 : 2.25);
      setEnabledCursor(
        blockCarousel.isFullWidthOnTheJourneyPage
          ? itemsLength > 3
          : itemsLength > 2,
      );
    }

    // Mobile
    else {
      setSlidesPerView(blockCarousel.isFullWidthOnTheJourneyPage ? 1.25 : 1.15);
      setEnabledCursor(false);
    }
  }, [
    blockCarousel.contentCollection.items.length,
    blockCarousel.isFullWidthOnTheJourneyPage,
    clientIsDesktop,
    clientIsTablet,
  ]);

  return (
    <CarouselWrapper
      className={className}
      $hasBlackBackground={hasBlackBackground}
      $hasGutters={blockCarousel.hasGutters}
      $isFullWidthOnTheJourneyPage={blockCarousel.isFullWidthOnTheJourneyPage}
    >
      {blockCarousel.heading && (
        <CarouselHeading
          heading={blockCarousel.heading}
          copy={blockCarousel.copy}
        />
      )}
      <SwiperWrapper
        $hasGutters={blockCarousel.hasGutters}
        $isFullWidthOnTheJourneyPage={blockCarousel.isFullWidthOnTheJourneyPage}
        {...cursorInteractions}
      >
        <StyledSwiper
          slidesPerView={slidesPerView}
          watchOverflow
          freeMode
          $hasGutters={blockCarousel.hasGutters}
          $isFullWidthOnTheJourneyPage={
            blockCarousel.isFullWidthOnTheJourneyPage
          }
        >
          {blockCarousel.contentCollection.items.map((userOrCompany) => (
            <SwiperSlide key={userOrCompany.name}>
              <StyledUserOrCompany
                userOrCompany={userOrCompany}
                mediaAspectRatio={blockCarousel.mediaAspectRatio}
                centerMedia={blockCarousel.centerMedia}
                $hasBlackBackground={hasBlackBackground}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperWrapper>
    </CarouselWrapper>
  );
};

export default BlockCarousel;
