import React from 'react';

import {BlockProps} from '..';

const BlockUnknown = ({block}: BlockProps) => (
  <p>
    Unknown Block Type: <strong>{block.__typename}</strong>
  </p>
);

export default BlockUnknown;
