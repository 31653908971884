import {Sizes} from '@/components/Heading';
import {Block} from '@/content/cms/types';

import BlockCarousel from './BlockCarousel';
import BlockColumns from './BlockColumns';
import BlockHeading from './BlockHeading';
import BlockJourneyIntro from './BlockJourneyIntro';
import BlockJourneyTimeline from './BlockJourneyTimeline';
import BlockLineChart from './BlockLineChart';
import BlockMedia from './BlockMedia';
import BlockMediaColumns from './BlockMediaColumns';
import BlockMilestones from './BlockMilestones';
import BlockParagraphsWithMedia from './BlockParagraphsWithMedia';
import BlockQuote from './BlockQuote';
import BlockRichText from './BlockRichText';
import BlockStat from './BlockStat';
import BlockStickyMediaContent from './BlockStickyMediaContent';
import BlockTeamQuote from './BlockTeamQuote';
import BlockUnknown from './BlockUnknown';
import BlockYoutube from './BlockYoutube';
import BlockYoutubeChapters from './BlockYoutubeChapters';

export interface BlockProps {
  block: Block;
  hasBlackBackground?: boolean;
  level?: Sizes;
}

interface Props {
  blocks: Block[];
}

interface Blocks {
  [key: string]: React.ComponentType<BlockProps>;
}

const availableBlocks = {
  BlockCarousel,
  BlockColumns,
  BlockHeading,
  BlockJourneyIntro,
  BlockJourneyTimeline,
  BlockLineChart,
  BlockMedia,
  BlockMediaColumns,
  BlockMilestones,
  BlockParagraphsWithMedia,
  BlockQuote,
  BlockRichText,
  BlockStat,
  BlockStickyMediaContent,
  BlockTeamQuote,
  BlockUnknown,
  BlockYoutube,
  BlockYoutubeChapters,
} as Blocks;

const Blocks = ({blocks}: Props) => (
  <>
    {blocks.map((block, index) => {
      const BlockComponent = availableBlocks[block.__typename] || BlockUnknown;
      return <BlockComponent block={block} key={index} />;
    })}
  </>
);

export default Blocks;
