import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import {MEDIA_WRAPPER_CLASS} from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import Source from '@/components/Source';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_S, SPACING_XS} from '@/theme/spacings';

interface WrapperProps {
  $hasGutters: boolean;
  $hasBlackBackground: boolean;
}

export const BlockColumnsWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};
`;

interface Props {
  $hasSource?: boolean;
}

export const StyledHeading = styled(Heading)<Props>`
  width: 100%;
  margin-top: 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(13)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(16)};
  }

  ${({$hasSource}) =>
    !$hasSource &&
    css`
      margin-bottom: ${SPACING_S};

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-bottom: ${SPACING_L};
      }
    `}
`;

export const StyledSource = styled(Source)`
  width: 100%;
  margin: ${SPACING_XS} 0 ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_XS} 0 ${SPACING_L};
    width: ${gridGutters(13)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(16)};
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_M};
    flex-direction: row;
  }

  .${MEDIA_WRAPPER_CLASS} {
    margin-bottom: ${SPACING_M};
  }
`;

interface LeftColumnProps {
  $onlyContainsMedia: boolean;
  $onlyColumn: boolean;
  $hasBlackBackground: boolean;
}

export const LeftColumn = styled(RichTextChunk)<LeftColumnProps>`
  width: ${gridGutters(10)};

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    > * {
      width: ${gridGutters(6)};
    }
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    > * {
      width: ${gridGutters(7.5)};
    }
  }

  &:is(div) {
    ${({$onlyColumn}) =>
      $onlyColumn
        ? css`
            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-right: ${gridGutters(7)};
            }

            @media ${QUERY_GREATER_THAN_TABLET} {
              margin-right: ${gridGutters(9)};
            }
          `
        : css`
            margin-bottom: ${SPACING_M};

            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-bottom: 0;
            }
          `}
  }
`;

interface RightColumnProps {
  $leftColumnOnlyContainsMedia: boolean;
  $hasBlackBackground: boolean;
}

export const RightColumn = styled(RichTextChunk)<RightColumnProps>`
  width: ${gridGutters(10)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-left: ${gridGutters(0.5)};
  }

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  > .${MEDIA_WRAPPER_CLASS} {
    margin-left: 0;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      margin-left: ${gridGutters(0.5)};
    }
  }

  @media ${QUERY_GREATER_THAN_MOBILE} {
    > * {
      width: ${gridGutters(6)};
    }
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    > * {
      width: ${gridGutters(7.5)};
    }
  }
`;
