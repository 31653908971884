import styled, {css} from 'styled-components';

import BaseMedia from '@/components/Media';
import {QUERY_GREATER_THAN_MOBILE, USING_MOUSE} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_XL} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_SLOTH,
} from '@/theme/transitions';

interface WrapperProps {
  $sticky?: boolean;
}

export const MediaColumnWrapper = styled.div<WrapperProps>`
  &&& {
    display: none;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: ${SPACING_XL};
    }

    @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
      ${({$sticky}) =>
        $sticky &&
        css`
          height: calc(100vh - ${SPACING_L} - ${SPACING_L});
        `}
    }
  }
`;

interface MediaProps {
  $active: boolean;
  $direction: 'up' | 'down';
  $sticky?: boolean;
}

export const StyledMedia = styled(BaseMedia)<MediaProps>`
  @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
    ${({$sticky, $active, $direction}) =>
      $sticky &&
      css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: clip-path ${TRANSITION_SPEED_SLOTH}s
          ${TRANSITION_EASE_CUBIC};
        will-change: clip-path;

        clip-path: ${$active
          ? 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
          : $direction === 'up'
          ? 'polygon(0 100%, 100% 100%, 100% 100%, 0 100%)'
          : 'polygon(0 0, 100% 0, 100% 0, 0 0)'};
      `}
  }
`;
