import {CarouselHeadingWrapper, StyledHeading, StyledCopy} from './styles';

export interface Props {
  heading: string;
  copy?: string;
}

const CarouselHeading = ({heading, copy}: Props) => (
  <CarouselHeadingWrapper>
    <StyledHeading level={3} animate>
      {heading}
    </StyledHeading>
    {copy && <StyledCopy>{copy}</StyledCopy>}
  </CarouselHeadingWrapper>
);

export default CarouselHeading;
