import styled, {css} from 'styled-components';
import {Swiper} from 'swiper/react';

import UserOrCompany from '@/components/UserOrCompany';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  IS_NOT_FIREFOX,
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
  USING_MOUSE,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_XL, SPACING_XXL} from '@/theme/spacings';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_GLACIAL,
} from '@/theme/transitions';

interface CarouselWrapperProps {
  $hasBlackBackground: boolean;
  $hasGutters?: boolean;
  $isFullWidthOnTheJourneyPage?: boolean;
}

export const CarouselWrapper = styled.div<CarouselWrapperProps>`
  width: 100%;
  margin-top: ${SPACING_L};

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      color: ${COLOR_WHITE};
    `};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};

    ${({$hasGutters, $isFullWidthOnTheJourneyPage}) =>
      $hasGutters &&
      !$isFullWidthOnTheJourneyPage &&
      css`
        padding: 0 ${gridGutters(1)};
      `}
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XXL};
  }
`;

interface SwiperProps {
  $hasGutters?: boolean;
  $isFullWidthOnTheJourneyPage?: boolean;
}

export const SwiperWrapper = styled.div<SwiperProps>`
  ${({$hasGutters, $isFullWidthOnTheJourneyPage}) =>
    !$isFullWidthOnTheJourneyPage &&
    css`
      margin: 0 ${gridGutters(-1)};

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin: 0 ${$hasGutters ? gridGutters(-2) : 0};
      }
    `}
`;

export const StyledSwiper = styled(Swiper)<SwiperProps>`
  ${({$hasGutters, $isFullWidthOnTheJourneyPage}) =>
    $isFullWidthOnTheJourneyPage
      ? css`
          padding-left: ${gridGutters(2)};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            padding-left: ${gridGutters(3)};
          }

          @media ${QUERY_GREATER_THAN_TABLET} {
            padding-left: ${gridGutters(4)};
          }
        `
      : css`
          padding-left: ${gridGutters(1)};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            padding-left: ${$hasGutters ? gridGutters(2) : 0};
          }
        `}

  @supports ${IS_NOT_FIREFOX} {
    @media ${USING_MOUSE} {
      .swiper-wrapper {
        transition-timing-function: ${TRANSITION_EASE_ON};

        /* !important used to override inline swiper transition-duration style. */
        transition-duration: ${TRANSITION_SPEED_GLACIAL}s !important;
      }
    }
  }
`;

interface ColorProps {
  $hasBlackBackground: boolean;
}

export const StyledUserOrCompany = styled(UserOrCompany)<ColorProps>`
  margin-right: ${gridGutters(1)};

  ${({$hasBlackBackground}) =>
    $hasBlackBackground &&
    css`
      p {
        color: white;
      }
    `}
`;
