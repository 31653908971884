import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  MutableRefObject,
} from 'react';

import {AnimatePresence, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {textReveal} from '@/components/Heading';
import {useLocomotiveElementConfig} from '@/config/locomotive';
import {Link, RichText} from '@/content/cms/types';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {
  COLOR_BLACK,
  COLOR_LATTE,
  COLOR_LIGHT_GREY,
  COLOR_ORANGE,
} from '@/theme/colors';
import {QUERY_GREATER_THAN_TABLET, USING_MOUSE} from '@/theme/mediaQueries';
import {
  EASE_CUBIC,
  EASE_ON,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_SPEED_SLOTH,
} from '@/theme/transitions';
import {rerange} from '@/utils/math';

import ChartTooltip from './ChartTooltip';
import {
  LineChartWrapper,
  Content,
  Track,
  StatsAndSourcesWrapper,
  Graphic,
  StyledHeading,
  Axis,
  AxisText,
  TooltipWrapper,
  CircleGroup,
  StyledChartSources,
  StatWrapper,
  StyledTextReveal,
  StyledSvg,
  LinePath,
  MaskPath,
  YLinesGroup,
  YRect,
  MaskCircle,
  OuterCircle,
  InnerCircle,
} from './styles';

const WRAPPER_ID = 'line-chart-wrapper';
const TRACK_ID = 'line-chart-track';
const GRAPHIC_ID = 'line-chart-graphic';

const SVG_VIEW_BOX_DIMENSIONS = {width: 811, height: 521};

const YEAR_SVG_AXIS = [
  {
    legend: 2000,
    x: 23,
  },
  {
    legend: 2010,
    x: 208,
  },
  {
    legend: 2020,
    x: 392,
  },
  {
    legend: 2030,
    x: 580,
  },
  {
    legend: 2040,
    x: 769,
  },
];

const DATA_POINTS = [
  {x: 322, y: 442},
  {x: 694, y: 219},
];

const TRANSITION_CUBIC = {
  duration: TRANSITION_SPEED_REGULAR,
  ease: EASE_CUBIC,
};

const TRANSITION_ON = {
  duration: TRANSITION_SPEED_REGULAR,
  ease: EASE_ON,
};

const TRANSITION_ON_SLOTH = {
  duration: TRANSITION_SPEED_SLOTH,
  ease: EASE_CUBIC,
};

const CIRCLE_MASK_VARIANTS = {
  initial: {scale: 0},
  visible: {scale: 0.7},
  hover: {scale: 1},
};

const CIRCLE_OUTER_VARIANTS = {
  initial: {scale: 0},
  visible: {scale: 0},
  hover: {scale: 1},
};

const CIRCLE_INNER_VARIANTS = {
  initial: {scale: 0},
  visible: {scale: 1},
  hover: {scale: 0.5},
};

const Y_LINES_VARIANTS = {
  initial: {scaleY: 0},
  visible: {scaleY: 1},
};

const setStrokeDashoffsetValue = (
  element: SVGPathElement,
  progress: number,
  length: number,
  invert?: boolean,
) => {
  const pixelOffset = (invert ? -1 : 1) * (length - length * progress);
  element.setAttribute(`stroke-dashoffset`, `${pixelOffset}px`);
};

interface Tooltip {
  copy: string;
  source: Link;
}

interface LineRevealConfig {
  maskElement: SVGPathElement;
  lengthRef: MutableRefObject<number>;
  reversed: boolean;
}

interface LineChartProps {
  title: RichText;
  sourceLabel: string;
  tooltips: Tooltip[];
}

const LineChart = ({title, sourceLabel, tooltips}: LineChartProps) => {
  // SCROLLING CONFIG
  const {scroll} = useLocomotiveScroll();
  const progressRef = useRef(0);

  const desktopAndUsingMouse = useClientMediaQuery(
    `${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE}`,
  );

  const wrapperLocomotiveProps = useLocomotiveElementConfig({id: WRAPPER_ID});

  const trackLocomotiveProps = useLocomotiveElementConfig({
    sticky: true,
    id: TRACK_ID,
    target: `#${TRACK_ID}`,
  });
  const desktopTrackLocomotiveProps = desktopAndUsingMouse
    ? trackLocomotiveProps
    : undefined;

  const graphicLocomotiveProps = useLocomotiveElementConfig({id: GRAPHIC_ID});

  const [inViewRef, inView] = useInView({triggerOnce: true, threshold: 0.1});

  // PATH LENGTHS
  const orangeStageOneLength = useRef(0);
  const orangeStageTwoLength = useRef(0);
  const orangeStageThreeLength = useRef(0);

  // DOM ELEMENTS
  const graphicWrapperRef = useRef<HTMLDivElement>(null);
  const axisRef = useRef<HTMLDivElement>(null);
  const tooltipWrapperRef = useRef<HTMLDivElement>(null);

  const svgRef = useRef<SVGSVGElement>(null);

  const greyPathRef = useRef<SVGPathElement>(null);
  const maskPathGreyRef = useRef<SVGPathElement>(null);

  const orangePathRef = useRef<SVGPathElement>(null);
  const orangePathTwoRef = useRef<SVGPathElement>(null);
  const orangePathThreeRef = useRef<SVGPathElement>(null);

  const maskPathOrangeRef = useRef<SVGPathElement>(null);
  const maskPathOrangeTwoRef = useRef<SVGPathElement>(null);
  const maskPathOrangeThreeRef = useRef<SVGPathElement>(null);

  const stageOneCircleRef = useRef<SVGGElement>(null);
  const stageTwoCircleRef = useRef<SVGGElement>(null);
  const linesRef = useRef<LineRevealConfig[]>([]);

  useEffect(() => {
    if (
      !maskPathOrangeRef.current ||
      !maskPathOrangeTwoRef.current ||
      !maskPathOrangeThreeRef.current
    )
      return;

    // Used to update line reveals programmatically
    linesRef.current = [
      {
        maskElement: maskPathOrangeRef.current,
        lengthRef: orangeStageOneLength,
        reversed: false,
      },
      {
        maskElement: maskPathOrangeTwoRef.current,
        lengthRef: orangeStageTwoLength,
        reversed: true,
      },
      {
        maskElement: maskPathOrangeThreeRef.current,
        lengthRef: orangeStageThreeLength,
        reversed: true,
      },
    ];
  }, []);

  // ANIMATION CONTROLS
  const pointControls = useAnimation();
  const pointTwoControls = useAnimation();
  const yLinesGroupControls = useAnimation();

  // CHART UI STATE
  const revealed = useRef(true);
  const [displayTooltip, setDisplayTooltip] = useState(-1);
  const [currentStep, setCurrentStep] = useState(-1);

  const handleTooltipHover = (tooltipIndex: number, hovering: boolean) => {
    if (desktopAndUsingMouse) {
      setDisplayTooltip(hovering ? tooltipIndex : -1);
      const controller = tooltipIndex === 0 ? pointControls : pointTwoControls;
      controller.start(hovering ? 'hover' : 'visible');
    }
  };

  useEffect(() => {
    pointControls.set('initial');
    pointTwoControls.set('initial');
    yLinesGroupControls.set('initial');

    const handleResize = () => {
      if (
        !maskPathOrangeRef.current ||
        !orangePathRef.current ||
        !greyPathRef.current ||
        !maskPathGreyRef.current ||
        !axisRef.current ||
        !tooltipWrapperRef.current ||
        !svgRef.current ||
        !maskPathOrangeTwoRef.current ||
        !orangePathTwoRef.current ||
        !orangePathThreeRef.current ||
        !maskPathOrangeThreeRef.current
      ) {
        return;
      }

      orangeStageOneLength.current = orangePathRef.current.getTotalLength();
      orangeStageTwoLength.current = orangePathTwoRef.current.getTotalLength();
      orangeStageThreeLength.current =
        orangePathThreeRef.current.getTotalLength();

      // Assign mask settings based on path length
      maskPathOrangeRef.current.setAttribute(
        'stroke-dasharray',
        `${orangeStageOneLength.current}px`,
      );
      maskPathOrangeRef.current.setAttribute(
        'stroke-dashoffset',
        `${orangeStageOneLength.current}px`,
      );

      // Assign mask settings based on path length
      maskPathOrangeTwoRef.current.setAttribute(
        'stroke-dasharray',
        `${orangeStageTwoLength.current}px ${orangeStageTwoLength.current}px`,
      );
      maskPathOrangeTwoRef.current.setAttribute(
        'stroke-dashoffset',
        `${orangeStageTwoLength.current}px`,
      );

      // Assign mask settings based on path length
      maskPathOrangeThreeRef.current.setAttribute(
        'stroke-dasharray',
        `${orangeStageThreeLength.current}px ${orangeStageThreeLength.current}px`,
      );
      maskPathOrangeThreeRef.current.setAttribute(
        'stroke-dashoffset',
        `${orangeStageThreeLength.current}px`,
      );

      // Assign grey path mask settings based on path length
      const greyPathLength = `${greyPathRef.current.getTotalLength()}px`;
      maskPathGreyRef.current.setAttribute('stroke-dasharray', greyPathLength);

      // Set positions of axis and tooltips
      const {width} = greyPathRef.current.getBoundingClientRect();
      const pathWidthPx = `${width}px`;
      const {height: svgHeight} = svgRef.current.getBoundingClientRect();
      axisRef.current.style.width = pathWidthPx;
      tooltipWrapperRef.current.style.width = pathWidthPx;
      tooltipWrapperRef.current.style.height = `${svgHeight}px`;
    };

    // Run on initial
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pointControls, pointTwoControls, yLinesGroupControls]);

  const handleProgress = useCallback(
    (progress: number) => {
      const inView = progress > 0.2;
      if (inView !== revealed.current) {
        yLinesGroupControls.start(inView ? 'visible' : 'initial');

        if (maskPathGreyRef.current && greyPathRef.current) {
          const greyPathRefLength = `${greyPathRef.current.getTotalLength()}px`;
          maskPathGreyRef.current.setAttribute(
            'stroke-dashoffset',
            inView ? '0px' : greyPathRefLength,
          );
        }

        revealed.current = inView;
      }

      if (linesRef.current.length) {
        progressRef.current = rerange(progress, 0.2, 0.7, 0, 0.9);

        // If theres no progress, hide reveals
        if (progressRef.current < 0) {
          linesRef.current.forEach(({maskElement, lengthRef, reversed}) =>
            setStrokeDashoffsetValue(
              maskElement,
              0,
              lengthRef.current,
              reversed,
            ),
          );
        }

        // Reveal first orange path
        else if (progressRef.current <= 1 / 3 && progressRef.current >= 0) {
          linesRef.current.forEach(
            ({maskElement, lengthRef, reversed}, index) =>
              setStrokeDashoffsetValue(
                maskElement,
                index === 0 ? progressRef.current * 3 : 0,
                lengthRef.current,
                reversed,
              ),
          );
        }

        // Reveal second orange path
        else if (progressRef.current > 1 / 3 && progressRef.current < 2 / 3) {
          linesRef.current.forEach(
            ({maskElement, lengthRef, reversed}, index) =>
              setStrokeDashoffsetValue(
                maskElement,
                index === 0
                  ? 1
                  : index === 1
                  ? (progressRef.current - 1 / 3) * 3
                  : 0,
                lengthRef.current,
                reversed,
              ),
          );
        } else if (progressRef.current > 2 / 3 && progressRef.current < 1) {
          linesRef.current.forEach(
            ({maskElement, lengthRef, reversed}, index) =>
              setStrokeDashoffsetValue(
                maskElement,
                index < 2 ? 1 : (progressRef.current - 2 / 3) * 3,
                lengthRef.current,
                reversed,
              ),
          );
        }

        // Ensure scrolling past, lines are fully revealed
        else if (progressRef.current >= 1) {
          linesRef.current.forEach(({maskElement, lengthRef, reversed}) =>
            setStrokeDashoffsetValue(
              maskElement,
              1,
              lengthRef.current,
              reversed,
            ),
          );
        }
      }

      // Get new stat step
      const step = Math.min(Math.floor(progressRef.current * 3), 2);

      if (step !== currentStep) {
        // Reset points
        if (step < 1) {
          pointControls.start('initial');
          pointControls.start('initial');
        }

        // Show first point
        if (step === 1) {
          pointControls.start('visible');
          pointTwoControls.start('initial');
        }

        // Show second point
        else if (step == 2) pointTwoControls.start('visible');

        setCurrentStep(step);
      }
    },
    [currentStep, pointControls, pointTwoControls, yLinesGroupControls],
  );

  useEffect(() => {
    if (!scroll || desktopAndUsingMouse === undefined) return;

    scroll.on(
      'scroll',
      (args: {currentElements: {[x: string]: {progress: number}}}) => {
        let inView = false;

        if (desktopAndUsingMouse) {
          if (typeof args.currentElements[WRAPPER_ID] === 'object') {
            const wrapperProgress = args.currentElements[WRAPPER_ID].progress;
            handleProgress(wrapperProgress);
            inView = true;
          }
        } else {
          if (typeof args.currentElements[GRAPHIC_ID] === 'object') {
            const graphicProgress = args.currentElements[GRAPHIC_ID].progress;
            const rerangedProgress = rerange(graphicProgress, 0.1, 0.8, 0, 1);
            handleProgress(rerangedProgress);
            inView = true;
          }
        }

        // Add will-change to hardware accelerate path if in view
        if (maskPathGreyRef.current) {
          maskPathGreyRef.current.style.willChange = inView
            ? 'stroke-dashoffset'
            : '';
        }
      },
    );
  }, [scroll, desktopAndUsingMouse, handleProgress]);

  const sources = useMemo(() => tooltips.map(({source}) => source), [tooltips]);

  return (
    <LineChartWrapper {...wrapperLocomotiveProps}>
      <Track id={TRACK_ID} />
      <StyledHeading level={3} richText={title} animate />
      <Content {...desktopTrackLocomotiveProps}>
        <StyledHeading level={3} $showOnDesktop richText={title} animate />
        <StatsAndSourcesWrapper>
          <StatWrapper ref={inViewRef}>
            <AnimatePresence exitBeforeEnter={desktopAndUsingMouse}>
              {tooltips.map(
                (tooltip, index) =>
                  (!desktopAndUsingMouse ||
                    index + 1 === currentStep ||
                    (index === tooltips.length - 1 && currentStep > index)) && (
                    <StyledTextReveal
                      key={index}
                      content={tooltip.copy}
                      reveal="word"
                      {...textReveal(5)}
                      enabledMasking
                      state={
                        !desktopAndUsingMouse
                          ? inView
                            ? 'animate'
                            : 'initial'
                          : undefined
                      }
                      animateDelay={
                        !desktopAndUsingMouse
                          ? TRANSITION_SPEED_REGULAR * index
                          : undefined
                      }
                    />
                  ),
              )}
            </AnimatePresence>
          </StatWrapper>
          <StyledChartSources
            showOnDesktop
            sourceLabel={sourceLabel}
            sources={sources}
          />
        </StatsAndSourcesWrapper>
        <Graphic ref={graphicWrapperRef} {...graphicLocomotiveProps}>
          <TooltipWrapper ref={tooltipWrapperRef}>
            {DATA_POINTS.map((datum, index) => (
              <ChartTooltip
                position={{
                  x: datum.x / SVG_VIEW_BOX_DIMENSIONS.width,
                  y: datum.y / SVG_VIEW_BOX_DIMENSIONS.height,
                }}
                copy={tooltips[index].copy}
                visible={index === displayTooltip}
                key={index}
                superscriptIndex={index + 1}
              />
            ))}
          </TooltipWrapper>
          <StyledSvg
            viewBox={`0 0 ${SVG_VIEW_BOX_DIMENSIONS.width} ${SVG_VIEW_BOX_DIMENSIONS.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={svgRef}
          >
            <defs>
              <mask id="mask-line-orange" maskUnits="userSpaceOnUse">
                <MaskPath
                  d="M3 466C132.191 463.553 236.596 456.497 323 441.871"
                  stroke={COLOR_LIGHT_GREY}
                  strokeDashoffset="100%"
                  strokeDasharray="100%"
                  ref={maskPathOrangeRef}
                />
              </mask>
              <mask id="mask-line-orange-two" maskUnits="userSpaceOnUse">
                <MaskPath
                  d="M692.81 221C607.153 346.658 505.023 411.06 323 441.871"
                  stroke={COLOR_LIGHT_GREY}
                  strokeDashoffset="100%"
                  strokeDasharray="100%"
                  ref={maskPathOrangeTwoRef}
                />
              </mask>
              <mask id="mask-line-orange-three" maskUnits="userSpaceOnUse">
                <MaskPath
                  d="M808.078 3C769.074 90.5393 732.876 162.118 693.151 220.5"
                  stroke={COLOR_LIGHT_GREY}
                  strokeDashoffset="100%"
                  strokeDasharray="100%"
                  ref={maskPathOrangeThreeRef}
                />
              </mask>
              <mask id="mask-line-grey" maskUnits="userSpaceOnUse">
                <MaskPath
                  d="M3 466C531 456 645 369 808.078 3"
                  stroke={COLOR_LATTE}
                  strokeDashoffset="100%"
                  strokeDasharray="100%"
                  ref={maskPathGreyRef}
                  $animate
                />
              </mask>
            </defs>
            <YLinesGroup
              key="y-lines-group"
              animate={yLinesGroupControls}
              variants={Y_LINES_VARIANTS}
              transition={TRANSITION_ON_SLOTH}
            >
              {YEAR_SVG_AXIS.map((year) => (
                <YRect
                  key={year.legend}
                  data-year={year.legend}
                  fill={COLOR_BLACK}
                  x={year.x}
                  y="1"
                  height={SVG_VIEW_BOX_DIMENSIONS.height - 1}
                />
              ))}
            </YLinesGroup>
            <g>
              <LinePath
                d="M3 466C531 456 645 369 808.078 3"
                stroke={COLOR_LIGHT_GREY}
                mask="url(#mask-line-grey)"
                ref={greyPathRef}
              />
              <LinePath
                d="M3 466C132.191 463.553 236.596 456.497 323 441.871"
                stroke={COLOR_ORANGE}
                mask="url(#mask-line-orange)"
                ref={orangePathRef}
              />
              <LinePath
                d="M692.81 221C607.153 346.658 505.023 411.06 323 441.871"
                stroke={COLOR_ORANGE}
                mask="url(#mask-line-orange-two)"
                ref={orangePathTwoRef}
              />
              <LinePath
                d="M808.078 3C769.074 90.5393 732.876 162.118 693.151 220.5"
                stroke={COLOR_ORANGE}
                mask="url(#mask-line-orange-three)"
                ref={orangePathThreeRef}
              />
            </g>
            {DATA_POINTS.map((point, index) => (
              <CircleGroup
                key={index}
                animate={index === 0 ? pointControls : pointTwoControls}
                $active={currentStep - 1 >= index}
                onMouseEnter={() =>
                  currentStep - 1 >= index && handleTooltipHover(index, true)
                }
                onMouseLeave={() =>
                  currentStep - 1 >= index && handleTooltipHover(index, false)
                }
                ref={index === 0 ? stageOneCircleRef : stageTwoCircleRef}
              >
                <MaskCircle
                  cx={point.x}
                  cy={point.y}
                  fill={COLOR_LATTE}
                  variants={CIRCLE_MASK_VARIANTS}
                  transition={TRANSITION_ON}
                />
                <OuterCircle
                  cx={point.x}
                  cy={point.y}
                  stroke={COLOR_ORANGE}
                  variants={CIRCLE_OUTER_VARIANTS}
                  transition={TRANSITION_CUBIC}
                />
                <InnerCircle
                  cx={point.x}
                  cy={point.y}
                  fill={COLOR_ORANGE}
                  variants={CIRCLE_INNER_VARIANTS}
                  transition={TRANSITION_ON}
                />
              </CircleGroup>
            ))}
          </StyledSvg>
          <Axis ref={axisRef}>
            {YEAR_SVG_AXIS.map(({legend, x}, index) => (
              <AxisText
                key={index}
                style={{left: `${(x / SVG_VIEW_BOX_DIMENSIONS.width) * 100}%`}}
              >
                {legend}
              </AxisText>
            ))}
          </Axis>
        </Graphic>
        <StyledChartSources sourceLabel={sourceLabel} sources={sources} />
      </Content>
    </LineChartWrapper>
  );
};

export default LineChart;
