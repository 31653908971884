import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_TABLET} from '@/theme/mediaQueries';
import {SPACING_S} from '@/theme/spacings';
import {
  TYPE_FONT_SANS,
  TYPE_SIZE_SANS_REGULAR,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';
import Vector2 from '@/utils/math/Vector2';

interface Props {
  $position: Vector2;
}

export const ChartTooltipWrapper = styled(motion.div)<Props>`
  position: absolute;
  background: ${COLOR_WHITE};
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: ${SPACING_S};
  width: ${gridGutters(8)};

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(4)};
  }

  ${({$position}) => css`
    left: ${$position.x * 100}%;
    bottom: ${100 - $position.y * 100}%;
    transform: translate(-50%, ${pxToRem(-32)});
  `};
`;

export const StyledCopy = styled(Copy)`
  margin: 0;
  ${TYPE_FONT_SANS}
  ${TYPE_WEIGHT_REGULAR}
  ${TYPE_SIZE_SANS_REGULAR}

  > sup {
    vertical-align: baseline;
    position: relative;
    line-height: 0;
    top: ${pxToRem(-4)};
    margin-left: ${pxToRem(2)};
  }
`;
