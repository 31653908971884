import {useEffect, useMemo} from 'react';

import {useInView} from 'react-intersection-observer';

import {useNav} from '@/contexts/nav';

interface NavTriggerProps {
  hasBlackBackground: boolean;
}

const NavTrigger = ({hasBlackBackground}: NavTriggerProps) => {
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: false,
  });
  const {setSubNavTheme, subNavTheme} = useNav();

  const navColor = useMemo(() => {
    return hasBlackBackground ? 'dark' : 'light';
  }, [hasBlackBackground]);

  useEffect(() => {
    if (!inView) return;
    if (subNavTheme === navColor) return;

    setSubNavTheme(navColor);
  }, [inView, navColor, setSubNavTheme, subNavTheme]);

  return <span ref={inViewRef}></span>;
};

export default NavTrigger;
