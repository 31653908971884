import styled from 'styled-components';

import Heading from '@/components/Heading';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_M, SPACING_S, SPACING_XS, SPACING_XXS} from '@/theme/spacings';

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin: ${SPACING_S} 0 ${SPACING_XXS};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_M} 0 ${SPACING_XS};
  }
`;
