import styled from 'styled-components';

import Copy from '@/components/icons/Copy';
import Link from '@/components/Link';
import {onFocusOutline, onHoverUnderline} from '@/theme/borders';
import {SPACING_XS} from '@/theme/spacings';
import {TYPE_SIZE_SANS_SMALL, TYPE_WEIGHT_REGULAR} from '@/theme/type';

export const SourceWrapper = styled.div`
  display: flex;
`;

export const StyledCopy = styled(Copy)`
  flex: none;
`;

export const Content = styled.div`
  ${TYPE_SIZE_SANS_SMALL}
  ${TYPE_WEIGHT_REGULAR}
  margin-left: ${SPACING_XS};
`;

export const StyledLink = styled(Link)`
  &:is(a) {
    ${onHoverUnderline(0.88)}
    ${onFocusOutline()}
  }

  /* Reset default link styles */
  color: inherit;
  text-decoration: none;
`;
