import {Sizes} from '@/components/Heading';
import {Block, RichText} from '@/content/cms/types';
import {TRANSITION_SPEED_REGULAR} from '@/theme/transitions';

import {BlockProps} from '..';
import {BlockHeaderWrapper, StyledHeading, StyledRichTextChunk} from './styles';

export interface BlockHeadingProps extends Block {
  styledHeading: RichText;
  headingLevel: Sizes;
  copyRichText?: RichText;
  hasGutters: boolean;
  rightAlign: boolean;
  copyInline?: boolean;
  showCloserToBlockBelow?: boolean;
  isAtTopOfPage?: boolean;
}

const BlockHeading = ({block}: BlockProps) => {
  const blockHeading = block as BlockHeadingProps;

  return (
    blockHeading && (
      <BlockHeaderWrapper
        $hasGutters={blockHeading.hasGutters}
        $rightAlign={blockHeading.rightAlign}
        $inline={blockHeading.copyInline}
        $showCloserToBlockBelow={blockHeading.showCloserToBlockBelow}
      >
        <StyledHeading
          $fullWidth={blockHeading.rightAlign}
          level={blockHeading.isAtTopOfPage ? 2.5 : blockHeading.headingLevel}
          richText={blockHeading.styledHeading}
          animate
        />
        {blockHeading.copyRichText && (
          <StyledRichTextChunk
            richText={blockHeading.copyRichText}
            animateCopy={blockHeading.isAtTopOfPage === true}
            copyRevealDelay={TRANSITION_SPEED_REGULAR}
            $atTopOfPage={blockHeading.isAtTopOfPage}
          />
        )}
      </BlockHeaderWrapper>
    )
  );
};

export default BlockHeading;
