import {Block, Link as ContentfulLink} from '@/content/cms/types';

import {BlockProps} from '..';
import {BlockQuoteWrapper, Quote, StyledSource} from './styles';

export interface BlockQuoteProps extends Block {
  quote: string;
  source: ContentfulLink;
}

const BlockQuote = ({block}: BlockProps) => {
  const blockQuote = block as BlockQuoteProps;
  return (
    <BlockQuoteWrapper>
      <Quote>{blockQuote.quote}</Quote>
      {blockQuote.source && <StyledSource link={blockQuote.source} />}
    </BlockQuoteWrapper>
  );
};

export default BlockQuote;
