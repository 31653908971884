import {useInView} from 'react-intersection-observer';

import {
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {SYLLABLE_REGEX} from '@/utils/validators';

import {INITIAL_WORD_OPACITY, QuoteTextItemWrapper, Word} from './styles';

const syllabifyText = (text: string) => text.match(SYLLABLE_REGEX);

const processWordForSyllableAnimation = (text: string) => {
  let lengthCounter = 0;

  return text.split(' ').map((word) => {
    const syllablesGroups = syllabifyText(word) || [];

    const delay = lengthCounter;
    lengthCounter = delay + syllablesGroups.length * TRANSITION_SPEED_FAST;

    syllablesGroups[syllablesGroups.length - 1] =
      syllablesGroups[syllablesGroups.length - 1] + ' ';

    return {
      word: word + ' ',
      delay,
      group: syllablesGroups,
    };
  });
};

const WORD_VARIANTS = {
  initial: {opacity: INITIAL_WORD_OPACITY},
  animate: {opacity: 1},
};

const IN_VIEW_CONFIG = {triggerOnce: true};

interface Props {
  text: string;
  className?: string;
  initialInView?: boolean;
}

const QuoteTextItem = ({text, className}: Props) => {
  const {ref, inView} = useInView(IN_VIEW_CONFIG);
  const splitText = processWordForSyllableAnimation(text);

  return (
    <QuoteTextItemWrapper
      ref={ref}
      className={className}
      initial="initial"
      animate={inView ? 'animate' : 'initial'}
      aria-label={text}
    >
      {splitText.map(({delay, group}, i) => (
        <span key={i} aria-hidden>
          {group.map((item, index) => (
            <Word
              key={index}
              custom={delay}
              variants={WORD_VARIANTS}
              transition={{
                delay: delay + index * TRANSITION_SPEED_FAST,
                duration: TRANSITION_SPEED_REGULAR,
              }}
            >
              {item}
            </Word>
          ))}
        </span>
      ))}
    </QuoteTextItemWrapper>
  );
};

export default QuoteTextItem;
