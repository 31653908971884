import styled, {css} from 'styled-components';

import Heading from '@/components/Heading';
import RichTextChunk from '@/components/RichTextChunk';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_S,
  SPACING_XL_NEGATIVE,
  SPACING_L,
  SPACING_XL,
  SPACING_XXL,
  SPACING_L_NEGATIVE,
  SPACING_M_NEGATIVE,
} from '@/theme/spacings';
import {
  TYPE_SIZE_HEADER_2_BASELINE_DESKTOP,
  TYPE_SIZE_HEADER_2_BASELINE_TABLET,
  TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_DESKTOP,
  TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_TABLET,
  TYPE_SIZE_LARGE_BASELINE_DESKTOP,
  TYPE_SIZE_LARGE_BASELINE_TABLET,
} from '@/theme/type';

interface BlockHeaderWrapperProps {
  $hasGutters: boolean;
  $rightAlign: boolean;
  $inline?: boolean;
  $hasCopy?: boolean;
  $showCloserToBlockBelow?: boolean;
}

export const BlockHeaderWrapper = styled.div<BlockHeaderWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XXL};
  }

  ${({$hasGutters}) =>
    $hasGutters &&
    css`
      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-right: ${gridGutters(1)};
        margin-left: ${gridGutters(1)};
      }
    `};

  ${({$showCloserToBlockBelow}) =>
    $showCloserToBlockBelow &&
    css`
      margin-bottom: ${SPACING_M_NEGATIVE};

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-bottom: ${SPACING_L_NEGATIVE};
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        margin-bottom: ${SPACING_XL_NEGATIVE};
      }
    `}

  ${({$rightAlign, $inline}) =>
    $rightAlign
      ? css`
          @media ${QUERY_GREATER_THAN_MOBILE} {
            margin-left: ${gridGutters(4)};
          }

          @media ${QUERY_GREATER_THAN_TABLET} {
            margin-left: ${gridGutters(5)};
          }
        `
      : $inline &&
        css`
          @media ${QUERY_GREATER_THAN_MOBILE} {
            flex-direction: row;
          }
        `};
`;

interface HeadingProps {
  $fullWidth: boolean;
}

export const StyledHeading = styled(Heading)<HeadingProps>`
  width: 100%;
  margin-top: 0;

  ${({$fullWidth}) =>
    !$fullWidth &&
    css`
      @media ${QUERY_GREATER_THAN_MOBILE} {
        width: ${gridGutters(11)};
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        width: ${gridGutters(13)};
      }
    `};
`;

interface RichTextChunkProps {
  $atTopOfPage?: boolean;
}

export const StyledRichTextChunk = styled(RichTextChunk)<RichTextChunkProps>`
  &:is(div) {
    align-self: flex-end;
    margin: ${SPACING_S} 0 0;
    width: 100%;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      margin-left: auto;
      width: ${gridGutters(6)};
      margin-bottom: calc(
        ${({$atTopOfPage}) =>
            $atTopOfPage
              ? TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_TABLET
              : TYPE_SIZE_HEADER_2_BASELINE_TABLET} -
          ${TYPE_SIZE_LARGE_BASELINE_TABLET}
      );
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      margin-bottom: calc(
        ${({$atTopOfPage}) =>
            $atTopOfPage
              ? TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_DESKTOP
              : TYPE_SIZE_HEADER_2_BASELINE_DESKTOP} -
          ${TYPE_SIZE_LARGE_BASELINE_DESKTOP}
      );
    }
  }
`;
