import Media from '@/components/Media';
import RichTextChunk from '@/components/RichTextChunk';
import {ParagraphWithMedia} from '@/content/cms/types';

import {ColumnWrapper, StyledHeading} from './styles';

interface Props {
  paragraphWithMedia: ParagraphWithMedia;
}

const Column = ({paragraphWithMedia}: Props) => (
  <ColumnWrapper>
    {paragraphWithMedia.media && (
      <Media
        media={paragraphWithMedia.media}
        aspectRatio="45:56"
        enableInnerLocomotive
      />
    )}
    <StyledHeading level={paragraphWithMedia.headingLevel} animate>
      {paragraphWithMedia.heading}
    </StyledHeading>
    <RichTextChunk richText={paragraphWithMedia.copy} />
  </ColumnWrapper>
);

export default Column;
