import React, {useEffect, useRef, useState} from 'react';

import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {useLocomotiveElementConfig} from '@/config/locomotive';
import {MediaWithDescription} from '@/content/cms/types';
import {useAnimationSettings} from '@/contexts/animationSettings';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {QUERY_GREATER_THAN_MOBILE, USING_MOUSE} from '@/theme/mediaQueries';
import {clamp} from '@/utils/math';

import {FullWidthMediaWrapper, StyledMedia} from './styles';

const SCROLL_THRESHOLD = 0.2;

interface Props {
  className?: string;
  media: MediaWithDescription;
  id?: string;
  ignoreAspectRatio?: boolean;
  lazy?: boolean;
  preload?: boolean;
}

const FullWidthMedia: React.FC<Props> = ({
  className,
  media,
  id,
  ignoreAspectRatio,
  lazy,
  preload,
  children,
}) => {
  const {enabled} = useAnimationSettings();
  const [animationsEnabled] = useState(enabled);
  const {scroll} = useLocomotiveScroll();
  const locomotiveProps = useLocomotiveElementConfig({id});
  const optionalLocomotiveProps = !!id && locomotiveProps;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tabletAndUsingMouse = useClientMediaQuery(
    `${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE}`,
  );

  useEffect(() => {
    if (!scroll || !id) return;

    scroll.on(
      'scroll',
      (args: {currentElements: {[x: string]: {progress: number}}}) => {
        if (!wrapperRef.current) return;

        // If element is in view...
        if (typeof args.currentElements[id] === 'object') {
          const progress = args.currentElements[id].progress;
          const thresholdProgress =
            progress < SCROLL_THRESHOLD ? 0 : (progress - SCROLL_THRESHOLD) * 4;
          const clampedThresholdProgress = clamp(thresholdProgress, 0, 1);
          const distanceFromEdge = Math.abs(clampedThresholdProgress - 1);
          const borderRadius = distanceFromEdge * 4;

          wrapperRef.current.style.willChange = tabletAndUsingMouse
            ? 'clip-path'
            : '';
          wrapperRef.current.style.clipPath = tabletAndUsingMouse
            ? `inset(0% ${distanceFromEdge}rem 0% ${distanceFromEdge}rem round ${borderRadius}px)`
            : '';
        } else wrapperRef.current.style.willChange = '';
      },
    );
  }, [id, scroll, tabletAndUsingMouse]);

  return (
    <FullWidthMediaWrapper
      className={className}
      ref={wrapperRef}
      {...optionalLocomotiveProps}
      $animateInOnLoad={!lazy && animationsEnabled}
      $animateOnScroll={!!id}
    >
      <StyledMedia
        preload={preload}
        media={media}
        aspectRatio={!!id ? '13:8' : undefined}
        ignoreAspectRatio={ignoreAspectRatio}
        lazy={lazy}
      />
      {children}
    </FullWidthMediaWrapper>
  );
};

export default FullWidthMedia;
