import {Link} from '@/content/cms/types';

import {
  ChartSourcesWrapper,
  StyledCopy,
  SourcesWrapper,
  Source,
} from './styles';

interface Props {
  className?: string;
  sourceLabel: string;
  sources: Link[];
  showOnDesktop?: boolean;
}

const ChartSources = ({
  className,
  sourceLabel,
  sources,
  showOnDesktop,
}: Props) => (
  <ChartSourcesWrapper className={className} $showOnDesktop={showOnDesktop}>
    <StyledCopy sansSize="small">{sourceLabel}</StyledCopy>
    <SourcesWrapper>
      {sources.map((source, index) => (
        <Source key={index}>{source.title}</Source>
      ))}
    </SourcesWrapper>
  </ChartSourcesWrapper>
);

export default ChartSources;
