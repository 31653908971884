import styled from 'styled-components';

import Copy from '@/components/Copy';
import FullWidthMedia from '@/components/FullWidthMedia';
import Heading from '@/components/Heading';
import {COLOR_BLACK_60, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
  USING_MOUSE,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S, SPACING_XL, SPACING_XXL} from '@/theme/spacings';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

export const BlockStickyMediaContentWrapper = styled.div`
  display: grid;
  grid-template-areas: 'main';
  margin-top: ${SPACING_L};
  position: relative;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XXL};
  }
`;

export const Track = styled.div`
  height: 200vh;
  grid-area: main;
  display: none;

  @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
    display: block;
  }
`;

export const StickyContent = styled.div`
  grid-area: main;
  height: 100vh;
`;

export const StyledFullWidthMedia = styled(FullWidthMedia)`
  height: 100vh;
  overflow: hidden;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${SPACING_S};
  max-width: 100%;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(9)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    max-width: ${gridGutters(11)};
  }
`;

export const StyledCopy = styled(Copy)`
  color: ${COLOR_WHITE};
  margin: 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(8)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(7)};
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${SPACING_XL} ${gridGutters(1)};
  color: ${COLOR_WHITE};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};
    padding: ${SPACING_XL} ${gridGutters(2)};
  }

  @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
    transform: translateY(100vh);
    padding-top: ${SPACING_XXL};
    padding-bottom: ${SPACING_XXL};
  }
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  background: ${COLOR_BLACK_60};
`;
