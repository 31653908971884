import YouTubeVideo from '@/components/YouTubeVideo';
import {eventPlayYouTubeVideo} from '@/config/tracking';
import {Block, Media as ContentfulMedia} from '@/content/cms/types';

import {BlockProps} from '..';

export interface BlockYouTubeProps extends Block {
  id: string;
  title?: string;
  duration?: string;
  thumbnail?: ContentfulMedia;
  caption?: string;
}

const BlockYoutube = ({block}: BlockProps) => {
  const blockYouTube = block as BlockYouTubeProps;
  return <YouTubeVideo video={blockYouTube} tracking={eventPlayYouTubeVideo} />;
};

export default BlockYoutube;
