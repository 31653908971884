import {useEffect, useRef, useState} from 'react';

import {SwiperSlide} from 'swiper/react';

import {CursorType} from '@/components/Cursor';
import {eventPlayChapterVideo} from '@/config/tracking';
import {
  Block,
  YouTubeVideo as ContentfulYouTubeVideo,
  YouTubeVideoCollection,
} from '@/content/cms/types';
import {useCursor} from '@/contexts/cursor';
import {IS_MOBILE} from '@/utils/environment';

import {BlockProps} from '..';
import PaginationDots from './PaginationDots';
import {
  StyledHeading,
  BlockYoutubeChaptersWrapper,
  StyledSwiper,
  StyledYouTubeVideo,
} from './styles';

import 'swiper/swiper.min.css';

interface BlockYoutubeChaptersProps extends Block {
  heading: string;
  headingLevel: number;
  videosCollection: YouTubeVideoCollection;
}

const BlockYoutubeChapters = ({block}: BlockProps) => {
  const blockYoutubeChapters = block as BlockYoutubeChaptersProps;

  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [playingList, setPlayingList] = useState(false);
  const {setType} = useCursor();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swiperRef = useRef<any>(); // Swiper does not export SwiperClass

  useEffect(() => {
    const updateCanSwipe = () => {
      if (!swiperRef.current) return;

      swiperRef.current.allowTouchMove = IS_MOBILE;
      swiperRef.current.update();
    };

    updateCanSwipe();

    window.addEventListener('resize', updateCanSwipe);

    return () => window.removeEventListener('resize', updateCanSwipe);
  }, []);

  const handlePaginationClick = (value: number, autoplay = false) => {
    setActiveCarouselIndex(value);

    if (!autoplay) setPlayingList(false);

    if (swiperRef.current) {
      const swiperInstance = swiperRef.current;
      swiperInstance.slideTo(value);
    }
  };

  return (
    <>
      <StyledHeading level={3} animate>
        {blockYoutubeChapters.heading}
      </StyledHeading>
      <BlockYoutubeChaptersWrapper>
        <StyledSwiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={1.2}
          centeredSlides
          centeredSlidesBounds
          threshold={0.05}
          allowTouchMove={false}
          onSlideChange={(swiper) => {
            if (swiper.activeIndex !== activeCarouselIndex) {
              setActiveCarouselIndex(swiper.activeIndex);
            }
          }}
        >
          {blockYoutubeChapters.videosCollection.items.map(
            (video: ContentfulYouTubeVideo, index: number) => {
              const isActive = activeCarouselIndex === index;
              return (
                <SwiperSlide key={video.id}>
                  <StyledYouTubeVideo
                    video={video}
                    index={index}
                    isActive={isActive}
                    canVideoPlay={isActive && playingList}
                    autoplay={isActive && playingList}
                    tracking={eventPlayChapterVideo(index + 1)}
                    onVideoPlaying={(isPlaying) => {
                      if (isPlaying) setType(CursorType.None);
                      setPlayingList(playingList ? isPlaying : true);
                    }}
                    onVideoComplete={(index) => {
                      if (index === undefined) return;

                      if (
                        index ===
                        blockYoutubeChapters.videosCollection.items.length - 1
                      ) {
                        setPlayingList(false);
                        return;
                      }
                      handlePaginationClick(index + 1, true);
                    }}
                    onMouseEnter={() => {
                      if (isActive && !playingList) setType(CursorType.Play);
                      if (isActive) setType(CursorType.None);
                      else {
                        setType(
                          activeCarouselIndex > index
                            ? CursorType.Back
                            : CursorType.Next,
                        );
                      }
                    }}
                    onMouseLeave={() => setType(CursorType.None)}
                    onClick={() => {
                      handlePaginationClick(index);
                      setType(CursorType.None);
                    }}
                    permitPlaylistPlay={() =>
                      handlePaginationClick(index, true)
                    }
                  />
                </SwiperSlide>
              );
            },
          )}
        </StyledSwiper>
      </BlockYoutubeChaptersWrapper>
      <PaginationDots
        active={activeCarouselIndex}
        onDotClick={handlePaginationClick}
        items={Array.from(
          {length: blockYoutubeChapters.videosCollection.items.length},
          (_, i) => i,
        )}
      />
    </>
  );
};

export default BlockYoutubeChapters;
