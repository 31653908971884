import styled, {css} from 'styled-components';

import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
  USING_MOUSE,
  USING_TOUCH,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_XL, SPACING_XXL_NEGATIVE} from '@/theme/spacings';

export interface ParagraphsWithMediaProps {
  $imageColumn: boolean;
  $centerContent?: boolean;
}

export const ParagraphsWithMediaWrapper = styled.div<ParagraphsWithMediaProps>`
  display: grid;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: 0 ${gridGutters(1)};
    grid-column-gap: ${gridGutters(1)};

    ${({$imageColumn}) =>
      $imageColumn
        ? `grid-template-columns: ${gridGutters(8)} ${gridGutters(7)}`
        : `grid-template-columns: ${gridGutters(7)} ${gridGutters(8)}`}
  }

  @media ${QUERY_GREATER_THAN_MOBILE} {
    ${({$centerContent}) =>
      !$centerContent &&
      css`
        @media ${USING_TOUCH} {
          margin-bottom: ${SPACING_XXL_NEGATIVE};
        }

        @media ${USING_MOUSE} {
          padding-bottom: ${SPACING_L};
        }
      `}
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-column-gap: ${gridGutters(2)};

    ${({$imageColumn}) =>
      $imageColumn
        ? `grid-template-columns: ${gridGutters(10)} ${gridGutters(8)}`
        : `grid-template-columns: ${gridGutters(8)} ${gridGutters(10)}`}
  }
`;

const COLUMN_BASE = css<ParagraphsWithMediaProps>`
  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
    margin-top: ${SPACING_XL};
    padding-top: ${SPACING_L};
  }
`;

export const FirstColumn = styled.div<ParagraphsWithMediaProps>`
  ${COLUMN_BASE}
  order: ${({$imageColumn}) => ($imageColumn ? 2 : 1)};

  ${({$centerContent}) =>
    $centerContent &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const SecondColumn = styled.div<ParagraphsWithMediaProps>`
  ${COLUMN_BASE}
  order: ${({$imageColumn}) => ($imageColumn ? 1 : 2)};
`;
