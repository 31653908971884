import styled from 'styled-components';
import {Swiper} from 'swiper/react';

import Heading from '@/components/Heading';
import YouTubeVideo from '@/components/YouTubeVideo';
import {gridGutters} from '@/theme/grid';
import {
  IS_NOT_FIREFOX,
  QUERY_GREATER_THAN_MOBILE,
  USING_MOUSE,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S, SPACING_XL} from '@/theme/spacings';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_GLACIAL,
} from '@/theme/transitions';

export const StyledHeading = styled(Heading)`
  margin: ${SPACING_L} 0 ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_XL} ${gridGutters(1)} ${SPACING_L};
  }
`;

export const BlockYoutubeChaptersWrapper = styled.div`
  margin: 0 -${gridGutters(1)};
`;

export const StyledSwiper = styled(Swiper)`
  padding-left: ${gridGutters(1)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-left: ${gridGutters(2)};
  }

  @supports ${IS_NOT_FIREFOX} {
    @media ${USING_MOUSE} {
      .swiper-wrapper {
        transition-timing-function: ${TRANSITION_EASE_ON};

        /* !important used to override inline swiper transition-duration style. */
        transition-duration: ${TRANSITION_SPEED_GLACIAL}s !important;
      }
    }
  }
`;

export const StyledYouTubeVideo = styled(YouTubeVideo)`
  margin-right: ${gridGutters(1)};
`;
