import {useLocomotiveElementConfig} from '@/config/locomotive';
import {ParagraphWithMedia} from '@/content/cms/types';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {USING_MOUSE} from '@/theme/mediaQueries';

import {MediaColumnWrapper, StyledMedia} from './styles';

interface MediaColumnProps {
  visibleImageIndex: number;
  paragraphs: ParagraphWithMedia[];
  id?: string;
}

const MediaColumn = ({visibleImageIndex, paragraphs, id}: MediaColumnProps) => {
  const transformedId = id?.replace(/ /g, '-').toLowerCase();
  const usingMouse = useClientMediaQuery(USING_MOUSE);
  const locomotiveProps = useLocomotiveElementConfig({
    sticky: true,
    id: transformedId,
    target: `#${transformedId}`,
  });

  const sticky = !!id;
  const optionalLocomotiveProps = usingMouse && sticky && locomotiveProps;

  return (
    <MediaColumnWrapper {...optionalLocomotiveProps} $sticky={sticky}>
      {paragraphs.map(
        (paragraph, index) =>
          paragraph.media && (
            <StyledMedia
              key={index}
              media={paragraph.media}
              ignoreAspectRatio={sticky}
              aspectRatio={sticky ? undefined : '3:4'}
              enableInnerLocomotive={!sticky}
              objectFit={sticky ? 'cover' : undefined}
              $active={visibleImageIndex === index}
              $direction={visibleImageIndex < index ? 'up' : 'down'}
              $sticky={sticky}
            />
          ),
      )}
    </MediaColumnWrapper>
  );
};

export default MediaColumn;
