import {useInView} from 'react-intersection-observer';

import {MilestoneType} from '@/content/cms/types';

import {
  CopyWrapper,
  StyledRichTextChunk,
  MilestoneWrapper,
  Year,
  StyledHeading,
  StyledMedia,
} from './styles';

interface MilestoneProps {
  block: MilestoneType;
  hasBlackBackground?: boolean;
}

const Milestone = ({block, hasBlackBackground = false}: MilestoneProps) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <MilestoneWrapper $hasBlackBackground={hasBlackBackground}>
      {block.year && <Year>{block.year}</Year>}
      {block.relatedMedia && !block.imageInline && (
        <StyledMedia $inline={block.imageInline} media={block.relatedMedia} />
      )}
      <StyledHeading
        $inline={!!!block.imageInline}
        level={3}
        richText={block.milestoneHeading}
        animate
      />
      <StyledHeading
        $inline={!!!block.imageInline}
        level={4}
        richText={block.milestoneHeading}
        animate
        $mobile
      />
      <CopyWrapper
        $inline={block.imageInline}
        ref={inViewRef}
        $carVisible={inView && block.isCarMedia}
      >
        {block.relatedMedia && block.imageInline && (
          <StyledMedia
            $inline={block.imageInline}
            $isCarMedia={block.isCarMedia}
            media={block.relatedMedia}
          />
        )}
        {block.milestoneCopy && (
          <StyledRichTextChunk
            $hasBlackBackground={hasBlackBackground}
            richText={block.milestoneCopy}
          />
        )}
      </CopyWrapper>
    </MilestoneWrapper>
  );
};

export default Milestone;
