import styled from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_L,
  SPACING_M,
  SPACING_S,
  SPACING_XL,
  SPACING_XS,
  SPACING_XXL,
} from '@/theme/spacings';

export const BlockMediaColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: ${SPACING_XL} ${gridGutters(1)} 0 ${gridGutters(4)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: ${SPACING_XXL};
    margin-left: ${gridGutters(5)};
  }
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(13)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(13)};
  }
`;

export const StyledCopy = styled(Copy)`
  width: 100%;
  margin: ${SPACING_XS} 0 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_S};
    width: ${gridGutters(7)};
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${SPACING_S};
  gap: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    gap: ${gridGutters(1)};
    margin-top: ${SPACING_L};
  }
`;
