import {
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {EventDataProps} from '@phntms/react-gtm';
import {AnimatePresence} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {BaseReactPlayerProps} from 'react-player/base';

import Button from '@/components/Button';
import Pause from '@/components/icons/Pause';
import Play from '@/components/icons/Play';
import Media from '@/components/Media';
import {YouTubeVideo as ContentfulYouTubeVideo} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {
  EASE_CUBIC,
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';

const FADE_IN_OUT_ANIMATION = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
  transition: {
    duration: TRANSITION_SPEED_REGULAR,
    ease: EASE_CUBIC,
  },
};

const SCALE_IN_OUT_ANIMATION = {
  initial: {scale: 0},
  animate: {
    scale: 1,
    transition: {
      duration: TRANSITION_SPEED_FAST,
      ease: EASE_ON,
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: TRANSITION_SPEED_FAST,
      ease: EASE_OUT,
    },
  },
};

import {
  YouTubeVideoWrapper,
  InfoWrapper,
  VideoWrapper,
  ControlsWrapper,
  IndexCopy,
  StyledHeading,
  StyledCopy,
  MediaWrapper,
  StyledYouTubePlayer,
  Center,
  Caption,
  Shadow,
} from './styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  video: ContentfulYouTubeVideo;
  tracking: EventDataProps;
  index?: number;
  canVideoPlay?: boolean;
  autoplay?: boolean;
  isActive?: boolean;
  onVideoPlaying?: (playing: boolean) => void;
  onVideoComplete?: (index?: number) => void;
  permitPlaylistPlay?: () => void;
  onClick?: () => void;
}

const YouTubeVideo = ({
  className,
  video,
  index,
  isActive,
  canVideoPlay = true,
  autoplay,
  tracking,
  onVideoPlaying,
  onVideoComplete,
  permitPlaylistPlay,
  ...rest
}: Props) => {
  const {
    youTubeChapterCurrentHeading,
    youTubePlayVideoCopy,
    youTubeChapterPrologueHeading,
  } = useGlobals();

  const reactPlayerRef = useRef<BaseReactPlayerProps>(null);
  const [playing, setPlaying] = useState(false);
  const sectionWrapperRef = useRef<HTMLDivElement>();
  const [inViewRef, inView] = useInView({
    threshold: 0.001,
    triggerOnce: true,
  });

  const currentChapterHeadingWithIndex =
    index !== undefined
      ? youTubeChapterCurrentHeading.replace('[index]', String(index))
      : undefined;

  const playVideoCopyWithDuration =
    video.duration &&
    youTubePlayVideoCopy.replace(
      '[duration]',
      video.duration ? video.duration : '',
    );

  const clientIsDesktop = useClientMediaQuery(QUERY_GREATER_THAN_MOBILE);

  const setIframeTabEvents = (allowTabbing: boolean) => {
    if (!reactPlayerRef.current) return;

    reactPlayerRef.current.wrapper
      .querySelector('iframe')
      .setAttribute('tabindex', allowTabbing ? '0' : '-1');
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onVideoPlaying) onVideoPlaying(!playing);
    setPlaying(!playing);
    if (permitPlaylistPlay) permitPlaylistPlay();
  };

  const handleVideoEnded = () => {
    setPlaying(false);
    setIframeTabEvents(false);
    if (onVideoComplete) onVideoComplete(index);
  };

  // Bind JSX element ref to intersection observer ref
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      sectionWrapperRef.current = node;
      inViewRef(node);
    },
    [inViewRef, sectionWrapperRef],
  );

  const videoPlaying = (playing && canVideoPlay) || autoplay;

  useEffect(() => {
    if (videoPlaying) setIframeTabEvents(true);
  }, [videoPlaying]);

  return (
    <YouTubeVideoWrapper className={className} ref={setRefs} {...rest}>
      <VideoWrapper
        onClick={(event) => {
          if (isActive) handleClick(event);
        }}
      >
        {inView && (
          <StyledYouTubePlayer
            ref={reactPlayerRef}
            playing={videoPlaying}
            url={`https://www.youtube.com/watch?v=${video.id}`}
            width="100%"
            height="100%"
            controls
            config={{playerVars: {origin: 'https://www.youtube.com'}}}
            onEnded={() => handleVideoEnded()}
            onReady={() => setIframeTabEvents(false)}
          />
        )}
        <AnimatePresence initial={false}>
          {!videoPlaying && video.thumbnail && (
            <MediaWrapper
              {...FADE_IN_OUT_ANIMATION}
              onClick={() => setPlaying(true)}
            >
              {video.thumbnail && (
                <Media media={video.thumbnail} aspectRatio="16:9" />
              )}
              <Shadow />
            </MediaWrapper>
          )}
        </AnimatePresence>
      </VideoWrapper>

      {video.caption && <Caption sansSize="small">{video.caption}</Caption>}

      {video.thumbnail && (
        <AnimatePresence initial={false}>
          {(!videoPlaying || !clientIsDesktop) && (
            <InfoWrapper
              {...FADE_IN_OUT_ANIMATION}
              $hasCaptionAbove={!!video.caption}
            >
              {index !== undefined && (
                <IndexCopy>
                  {index === 0
                    ? youTubeChapterPrologueHeading
                    : currentChapterHeadingWithIndex}
                </IndexCopy>
              )}
              <StyledHeading level={4}>{video.title}</StyledHeading>
              <ControlsWrapper>
                <Button
                  onClick={handleClick}
                  aria-label={videoPlaying ? 'Pause video' : 'Play video'}
                  tracking={tracking}
                  round
                >
                  <AnimatePresence exitBeforeEnter initial={false}>
                    {videoPlaying ? (
                      <Center key="paused" {...SCALE_IN_OUT_ANIMATION}>
                        <Pause />
                      </Center>
                    ) : (
                      <Center key="playing" {...SCALE_IN_OUT_ANIMATION}>
                        <Play />
                      </Center>
                    )}
                  </AnimatePresence>
                </Button>
                <StyledCopy sansSize="regular">
                  {playVideoCopyWithDuration}
                </StyledCopy>
              </ControlsWrapper>
            </InfoWrapper>
          )}
        </AnimatePresence>
      )}
    </YouTubeVideoWrapper>
  );
};

export default YouTubeVideo;
