import React, {ReactNode, useMemo} from 'react';

import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {INLINES, BLOCKS, Node, MARKS, Block} from '@contentful/rich-text-types';

import Copy, {revealState, sansSizes, serifSizes} from '@/components/Copy';
import Media from '@/components/Media';
import Popup from '@/components/Popup';
import {eventClickRichTextLink} from '@/config/tracking';
import {
  LinkWithId,
  Media as ContentfulMedia,
  RichText,
  RichTextWithLinksAndMedia,
} from '@/content/cms/types';

import {
  StyledRichTextChunk,
  StyledLink,
  StyledHeading,
  StyledList,
  StyledNumberedList,
} from './styles';

const renderOptions = (
  links?: RichTextWithLinksAndMedia,
  className?: string,
  parallaxMedia?: boolean,
  copySerifSize?: serifSizes,
  copySansSize?: sansSizes,
  mediaAspectRatio?: string,
  animateCopy?: boolean,
  copyRevealState?: revealState,
  copyRevealDelay?: number,
) => {
  // Create link map
  const allLinks =
    links && links.entries
      ? [...links.entries.hyperlink, ...links.entries.inline]
      : [];
  const linkMap = allLinks.reduce(
    (memo, link) => memo.set(link.sys.id, link),
    new Map<string, LinkWithId>(),
  );

  // Create media map
  const mediaMap = links?.assets?.block.reduce(
    (memo, media) => memo.set(media.sys.id, media),
    new Map<string, ContentfulMedia>(),
  );

  return {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node: Node, children: ReactNode) => {
        const value = (children as string[])[0];
        const link = linkMap.get(node.data.target.sys.id);

        if (link) {
          return (
            <Popup text={link.description}>
              <StyledLink link={link} tracking={eventClickRichTextLink(value)}>
                {value}
              </StyledLink>
            </Popup>
          );
        }
      },

      [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
        const link = linkMap.get(node.data.target.sys.id);

        if (link) {
          return (
            <Popup text={link.description}>
              <StyledLink
                link={link}
                tracking={eventClickRichTextLink(link?.title)}
              >
                {link?.title}
              </StyledLink>
            </Popup>
          );
        }
      },

      [BLOCKS.UL_LIST]: (node: Node, children: ReactNode) => {
        return <StyledList>{children}</StyledList>;
      },

      [BLOCKS.OL_LIST]: (node: Node, children: ReactNode) => {
        return <StyledNumberedList>{children}</StyledNumberedList>;
      },

      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        const media = mediaMap?.get(node.data.target.sys.id);
        if (media)
          return (
            <Media
              media={media}
              enableWrapperLocomotive={parallaxMedia}
              enableInnerLocomotive
              aspectRatio={mediaAspectRatio}
            />
          );
      },

      [MARKS.UNDERLINE]: (_node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) return <span>{children}</span>;
      },

      [BLOCKS.PARAGRAPH]: (_node: Node, children: ReactNode) => {
        /**
         * We were experiencing paragraphs not showing up if they started with
         * a link. Even if a paragraph starts with a link, it'll return an
         * empty paragraph first. So we have to check the first 2 elements.
         *
         * https://phantomstudios.atlassian.net/jira/software/projects/ER/boards/463?selectedIssue=ER-94
         */
        const content = (children as string[])[0] || (children as string[])[1];

        if (content) {
          return (
            <Copy
              className={className}
              serifSize={copySerifSize}
              sansSize={copySansSize}
              animate={animateCopy}
              revealState={copyRevealState}
              revealDelay={copyRevealDelay}
            >
              {children}
            </Copy>
          );
        }
      },

      [BLOCKS.HEADING_1]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) {
          return (
            <StyledHeading
              className={className}
              level={1}
              animate
              node={node as Block}
            />
          );
        }
      },

      [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) {
          return (
            <StyledHeading
              className={className}
              level={2}
              animate
              node={node as Block}
            />
          );
        }
      },

      [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) {
          return (
            <StyledHeading
              className={className}
              level={3}
              animate
              node={node as Block}
            />
          );
        }
      },

      [BLOCKS.HEADING_4]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) return <StyledHeading level={4} node={node as Block} />;
      },

      [BLOCKS.HEADING_5]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) return <StyledHeading level={5} node={node as Block} />;
      },

      [BLOCKS.HEADING_6]: (node: Node, children: ReactNode) => {
        const content = (children as string[])[0];
        if (content) return <StyledHeading level={6} node={node as Block} />;
      },
    },
  };
};

export interface Props {
  className?: string;
  richText: RichText;
  parallaxMedia?: boolean;
  copySerifSize?: serifSizes;
  copySansSize?: sansSizes;
  /** Example: 16:9. */
  mediaAspectRatio?: string;
  animateCopy?: boolean;
  copyRevealState?: revealState;
  copyRevealDelay?: number;
}

const RichTextChunk = ({
  className,
  richText,
  parallaxMedia,
  copySerifSize = 'large',
  copySansSize,
  mediaAspectRatio,
  animateCopy,
  copyRevealState,
  copyRevealDelay,
}: Props) => {
  const content = useMemo(() => {
    return documentToReactComponents(
      richText.json,
      renderOptions(
        richText.links,
        className,
        parallaxMedia,
        copySerifSize,
        copySansSize,
        mediaAspectRatio,
        animateCopy,
        copyRevealState,
        copyRevealDelay,
      ),
    );
  }, [
    richText.json,
    richText.links,
    className,
    parallaxMedia,
    copySerifSize,
    copySansSize,
    mediaAspectRatio,
    animateCopy,
    copyRevealState,
    copyRevealDelay,
  ]);
  return (
    <StyledRichTextChunk className={className}>{content}</StyledRichTextChunk>
  );
};

export default RichTextChunk;
