import styled, {css} from 'styled-components';

import {LinePosition} from '@/components/JourneyElement';
import {BORDER_RADIUS_MEDIUM} from '@/theme/borders';
import {COLOR_ALMOST_BLACK, COLOR_WHITE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {TRANSITION_EASE_ON, TRANSITION_SPEED_FAST} from '@/theme/transitions';
import {
  TYPE_FONT_SANS,
  TYPE_SIZE_REGULAR,
  TYPE_SIZE_SANS_SMALL,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';

interface LabelProps {
  $position: LinePosition;
  $inView: boolean;
  $color: string;
  $hideBorder: boolean;
  $isChapter: boolean;
}

export const Label = styled.div<LabelProps>`
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 0 0 ${BORDER_RADIUS_MEDIUM} ${COLOR_ALMOST_BLACK};
  background-color: ${COLOR_ALMOST_BLACK};
  opacity: 1;

  ${({$isChapter}) =>
    $isChapter
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: block;
  }

  ${({$position}) =>
    $position === 'left'
      ? css`
          left: 0;
        `
      : $position === 'right'
      ? css`
          right: 0;

          ${LabelCircle} {
            right: ${pxToRem(-13)};
          }
        `
      : css`
          top: 0%;
          left: 50%;
          transform: translate(-50%);
          box-shadow: 0 0 0 ${BORDER_RADIUS_MEDIUM} ${COLOR_ALMOST_BLACK};

          ${LabelContents} {
            &:before,
            &:after {
              opacity: 0;
            }
          }

          ${LabelCircle} {
            top: 0;
            left: 50%;
          }
        `};

  ${({$inView, $color, $hideBorder}) =>
    $inView &&
    css`
      opacity: 1;

      ${LabelText} {
        color: ${$color};
      }

      ${LabelCircle} {
        border: 1px solid ${$color};
        transition: border ${TRANSITION_SPEED_FAST * 3}s ${TRANSITION_EASE_ON}
          ${$hideBorder ? 0 : TRANSITION_SPEED_FAST * 3}s;
      }

      ${LabelInnerCircle} {
        background-color: ${$color};
      }

      ${LabelContents} {
        opacity: 1;
        &:before,
        &:after {
          width: 100%;
          height: 100%;
        }

        ${$hideBorder &&
        css`
          &:before,
          &:after {
            opacity: 0;
          }
        `};

        &:before {
          border-top-color: ${$color};
          border-right-color: ${$color};
          transition: width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON},
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST}s;
        }

        &:after {
          border-bottom-color: ${$color};
          border-left-color: ${$color};
          transition: border-color 0s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            width ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 2}s,
            height ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON}
              ${TRANSITION_SPEED_FAST * 3}s;
        }
      }
    `}
`;

interface LabelCircleProps {
  $position: LinePosition;
}

interface PositionProps {
  $position: LinePosition;
}

export const LabelCircle = styled.span<LabelCircleProps>`
  width: ${pxToRem(13)};
  height: ${pxToRem(13)};
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_ALMOST_BLACK};
  box-shadow: 0 0 0 4px ${COLOR_ALMOST_BLACK};
  border: 1px solid transparent;

  ${({$position}) =>
    $position === 'left'
      ? css`
          transform: translate(-50%, 40%);
        `
      : css`
          transform: translate(50%, 40%);
        `}
`;

export const LabelInnerCircle = styled.span`
  display: block;
  height: ${pxToRem(3)};
  width: ${pxToRem(3)};
  background-color: ${COLOR_WHITE};
  border-radius: 50%;
`;

export const LabelContentsWrapper = styled.div<PositionProps>`
  position: relative;
  display: flex;

  ${({$position}) =>
    $position === 'left'
      ? css`
          flex-direction: row;
          padding: ${pxToRem(6)} ${pxToRem(10)} ${pxToRem(4)} 0;

          @media ${QUERY_GREATER_THAN_TABLET} {
            padding: ${pxToRem(10)} ${pxToRem(17)} ${pxToRem(8)} 0;
          }
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

export const LabelContents = styled.div`
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px transparent;
  opacity: 0.4;
  transition: opacity 0.3s ease;

  &:before,
  &:after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    border: 1px solid transparent;
    width: 0;
    height: 0;
  }

  &:before {
    top: 0;
    left: 0;
  }

  &:after {
    bottom: 0;
    right: 0;
  }
`;

interface LabelTextProps {
  $position: LinePosition;
}

export const LabelText = styled.div<LabelTextProps>`
  max-width: ${gridGutters(2)};
  ${({$position}) =>
    $position === 'left'
      ? css`
          padding-left: ${pxToRem(5)};
        `
      : css`
          padding-right: ${pxToRem(5)};
        `}
`;

export const LabelTitle = styled.div`
  ${TYPE_FONT_SANS}
  ${TYPE_SIZE_REGULAR}
`;

export const LabelSubtitle = styled.div`
  ${TYPE_FONT_SANS}
  ${TYPE_WEIGHT_REGULAR}
  ${TYPE_SIZE_SANS_SMALL}
`;
