import styled from 'styled-components';

import Source from '@/components/Source';
import {COLOR_ORANGE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_M, SPACING_XS} from '@/theme/spacings';
import {TYPE_SIZE_HEADER_3, TYPE_WEIGHT_BOLD} from '@/theme/type';

export const BlockQuoteWrapper = styled.div``;

export const Quote = styled.blockquote`
  ${TYPE_SIZE_HEADER_3}
  ${TYPE_WEIGHT_BOLD}
  position: relative;

  /* Reset default blockquote style */
  margin: 0;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    min-width: ${gridGutters(12)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    min-width: ${gridGutters(15)};
  }

  &:before {
    content: open-quote;
    margin-right: ${SPACING_XS};
    color: ${COLOR_ORANGE};

    @media ${QUERY_GREATER_THAN_MOBILE} {
      position: absolute;
      right: 100%;
    }
  }

  &:after {
    content: close-quote;
    margin-left: ${SPACING_XS};
    color: ${COLOR_ORANGE};
  }
`;

export const StyledSource = styled(Source)`
  margin-top: ${SPACING_M};
`;
