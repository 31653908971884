import styled, {css, keyframes} from 'styled-components';

import Media from '@/components/Media';
import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE, USING_MOUSE} from '@/theme/mediaQueries';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_SPEED_SLOTH,
} from '@/theme/transitions';

const SCALE_IN_KEYFRAMES = keyframes`
  to {
    transform: scale(1) translateY(0);
  }
`;

const FADE_IN_KEYFRAMES = keyframes`
  to {
    opacity: 1;
  }
`;

export const StyledMedia = styled(Media)`
  height: 100%;
`;

interface Props {
  $animateOnScroll?: boolean;
  $animateInOnLoad?: boolean;
}

export const FullWidthMediaWrapper = styled.div<Props>`
  ${({$animateOnScroll}) =>
    $animateOnScroll &&
    css`
      > ${StyledMedia} {
        border-radius: 0;
      }

      position: relative;
      margin-right: -${gridGutters(1)};
      margin-left: -${gridGutters(1)};

      @media ${QUERY_GREATER_THAN_MOBILE} and ${USING_MOUSE} {
        clip-path: inset(
          0% ${gridGutters(1)} 0% ${gridGutters(1)} round ${BORDER_RADIUS_SMALL}
        );
        transition: clip-path ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};
      }
    `}

  ${({$animateInOnLoad}) =>
    $animateInOnLoad &&
    css`
      transform: scale(0.8) translateY(20%);
      animation: ${SCALE_IN_KEYFRAMES} ${TRANSITION_SPEED_SLOTH}s
        ${TRANSITION_EASE_ON} forwards;

      > ${StyledMedia} {
        opacity: 0;
        animation: ${FADE_IN_KEYFRAMES} ${TRANSITION_SPEED_REGULAR}s
          ${TRANSITION_EASE_ON} forwards;
      }

      &,
      > ${StyledMedia} {
        animation-delay: ${TRANSITION_SPEED_REGULAR}s;
      }
    `}
`;
