import styled from 'styled-components';

import Copy from '@/components/Copy';
import FullWidthMedia from '@/components/FullWidthMedia';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S, SPACING_XL} from '@/theme/spacings';
import {TYPE_WEIGHT_REGULAR} from '@/theme/type';

export const StyledFullWidthMedia = styled(FullWidthMedia)`
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }
`;

export const Caption = styled(Copy)`
  margin: ${SPACING_S} 0 0;
  ${TYPE_WEIGHT_REGULAR}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(8)};
  }
`;
