import styled from 'styled-components';

import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_XL, SPACING_XXL} from '@/theme/spacings';

export const ParagraphColumnWrapper = styled.div`
  margin-top: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-top: 0;
  }

  > div:last-child {
    margin-bottom: 0;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      margin-bottom: ${SPACING_XXL};
    }
  }
`;
