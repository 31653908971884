import {BLOCKS, Text, TopLevelBlock} from '@contentful/rich-text-types';

import {Sizes} from '@/components/Heading';
import {Block, Link, RichText} from '@/content/cms/types';

import {BlockProps} from '../..';
import {
  BlockColumnsWrapper,
  Columns,
  StyledHeading,
  StyledSource,
  LeftColumn,
  RightColumn,
} from './styles';

export interface BlockColumnsProps extends Block {
  styledHeading?: RichText;
  headingSource?: Link;
  headingLevel?: Sizes;
  leftColumn: RichText;
  rightColumn?: RichText;
  hasGutters: boolean;
  hasBlackBackground: boolean;
}

const MilestoneColumns = ({block, hasBlackBackground = false}: BlockProps) => {
  const blockColumns = block as BlockColumnsProps;

  const containsEmptyParagraph = (block: TopLevelBlock) => {
    return (
      block.nodeType === BLOCKS.PARAGRAPH && !(block.content[0] as Text).value
    );
  };

  const onlyContainsMedia = (richText?: RichText) => {
    if (!richText) return false;
    const content = richText.json.content;
    const hasMedia = content[0].nodeType === BLOCKS.EMBEDDED_ASSET;
    return (
      hasMedia &&
      (content.length === 1 ||
        (content.length === 2 && containsEmptyParagraph(content[1])))
    );
  };

  const leftColumnOnlyContainsMedia = onlyContainsMedia(
    blockColumns.leftColumn,
  );

  return (
    <BlockColumnsWrapper
      $hasGutters={blockColumns.hasGutters}
      $hasBlackBackground={hasBlackBackground}
    >
      {blockColumns.styledHeading && (
        <>
          <StyledHeading
            level={blockColumns.headingLevel}
            richText={blockColumns.styledHeading}
            $hasSource={!!blockColumns.headingSource}
          />
          {blockColumns.headingSource && (
            <StyledSource link={blockColumns.headingSource} />
          )}
        </>
      )}
      <Columns>
        {blockColumns.leftColumn && (
          <LeftColumn
            richText={blockColumns.leftColumn}
            parallaxMedia={leftColumnOnlyContainsMedia}
            $onlyContainsMedia={leftColumnOnlyContainsMedia}
            $onlyColumn={!blockColumns.rightColumn}
            $hasBlackBackground={hasBlackBackground}
          />
        )}
        {blockColumns.rightColumn && (
          <RightColumn
            richText={blockColumns.rightColumn}
            $leftColumnOnlyContainsMedia={leftColumnOnlyContainsMedia}
            $hasBlackBackground={hasBlackBackground}
          />
        )}
      </Columns>
    </BlockColumnsWrapper>
  );
};

export default MilestoneColumns;
