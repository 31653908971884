import {useState} from 'react';

import {Block, ParagraphWithMedia} from '@/content/cms/types';

import {BlockProps} from '..';
import MediaColumn from './MediaColumn';
import ParagraphColumn from './ParagraphColumn';
import {ParagraphsWithMediaWrapper, FirstColumn, SecondColumn} from './styles';

interface ParagraphWithMediaCollection {
  items: ParagraphWithMedia[];
}

export interface BlockParagraphsWithMediaProps extends Block {
  paragraphsCollection: ParagraphWithMediaCollection;
  imageAlignLeft: boolean;
  id?: string;
  centerParagraphs?: boolean;
}

const BlockParagraphsWithMedia = ({block}: BlockProps) => {
  const blockParagraphsWithMedia = block as BlockParagraphsWithMediaProps;
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);
  return (
    <ParagraphsWithMediaWrapper
      $imageColumn={blockParagraphsWithMedia.imageAlignLeft}
      $centerContent={blockParagraphsWithMedia.centerParagraphs}
    >
      <FirstColumn
        id={blockParagraphsWithMedia.id || undefined}
        $imageColumn={blockParagraphsWithMedia.imageAlignLeft}
        $centerContent={blockParagraphsWithMedia.centerParagraphs}
      >
        <ParagraphColumn
          paragraphs={blockParagraphsWithMedia.paragraphsCollection.items}
          setVisibleImageIndex={setVisibleImageIndex}
        />
      </FirstColumn>
      <SecondColumn $imageColumn={blockParagraphsWithMedia.imageAlignLeft}>
        <MediaColumn
          paragraphs={blockParagraphsWithMedia.paragraphsCollection.items}
          visibleImageIndex={visibleImageIndex}
          id={blockParagraphsWithMedia.id}
        />
      </SecondColumn>
    </ParagraphsWithMediaWrapper>
  );
};

export default BlockParagraphsWithMedia;
