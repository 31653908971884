import styled from 'styled-components';

import Heading from '@/components/Heading';
import Source from '@/components/Source';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_M} from '@/theme/spacings';

export const BlockStatWrapper = styled.div`
  display: grid;
  grid-row-gap: ${SPACING_M};
  grid-template-areas:
    'stat'
    'heading'
    'source';

  @media ${QUERY_GREATER_THAN_MOBILE} {
    grid-row-gap: 0;
    grid-column-gap: ${gridGutters(1)};
    grid-template-columns: repeat(2, ${gridGutters(6)});
    grid-template-areas:
      'stat heading'
      'source heading'
      'source heading';
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-template-columns: repeat(2, ${gridGutters(7)});
  }
`;

export const StyledStat = styled(Heading)`
  grid-area: stat;
  margin: 0;
`;

export const StyledSource = styled(Source)`
  grid-area: source;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    max-width: ${gridGutters(6)};
  }
`;

export const StyledHeading = styled(Heading)`
  grid-area: heading;
  margin-top: 0;
`;
