import {AnimateSharedLayout} from 'framer-motion';

import {pxToRem} from '@/theme/grid';
import {EASE_ON, TRANSITION_SPEED_REGULAR} from '@/theme/transitions';

import {PaginationDotsWrapper, Dot} from './styles';

interface Props {
  active: number;
  items: number[];
  onDotClick: (value: number) => void;
}

const DOT_VARIANTS = {
  active: {
    width: pxToRem(40),
  },
  inactive: {
    width: pxToRem(7),
  },
};

const PaginationDots = ({active, items, onDotClick}: Props) => (
  <AnimateSharedLayout>
    <PaginationDotsWrapper layout>
      {items.map((item) => (
        <Dot
          key={item}
          $active={active === item}
          initial={'inactive'}
          animate={active === item ? 'active' : 'inactive'}
          variants={DOT_VARIANTS}
          onClick={() => onDotClick(item)}
          aria-label={`Navigate to video ${item + 1}`}
          transition={{
            duration: TRANSITION_SPEED_REGULAR,
            ease: EASE_ON,
          }}
        />
      ))}
    </PaginationDotsWrapper>
  </AnimateSharedLayout>
);

export default PaginationDots;
