import {useEffect, useRef} from 'react';

import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {BlockProps} from '@/components/Blocks';
import Media from '@/components/Media';
import {useLocomotiveElementConfig} from '@/config/locomotive';
import {Block, Media as ContentfulMedia, User} from '@/content/cms/types';

import {
  BlockTeamQuoteWrapper,
  SourceWrapper,
  Quote,
  QuoteMark,
  QuoteName,
  QuoteRole,
  MediaWrapper,
  QuoteWrapper,
} from './styles';

const ID = 'block-team-quote-media';

interface BlockTeamQuoteProps extends Block {
  quote: string;
  source: User;
  image?: ContentfulMedia;
}

const BlockTeamQuote = ({block}: BlockProps) => {
  const blockTeamQuote = block as BlockTeamQuoteProps;

  const locomotiveProps = useLocomotiveElementConfig({id: ID});
  const {scroll} = useLocomotiveScroll();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scroll) return;

    scroll.on(
      'scroll',
      (args: {currentElements: {[x: string]: {progress: number}}}) => {
        if (typeof args.currentElements[ID] === 'object' && ref.current) {
          const progress = args.currentElements[ID].progress - 0.4;
          const progressPercentage = `${(progress / 2) * 70}%`;
          ref.current.style.transform = `translateY(${progressPercentage}) rotate(-1.5deg)`;
        }
      },
    );
  }, [scroll]);

  return (
    <BlockTeamQuoteWrapper {...locomotiveProps}>
      <SourceWrapper>
        <QuoteMark $isOpen $showOnDesktop aria-hidden />
        <QuoteName level={6}>{blockTeamQuote.source.name}</QuoteName>
        <QuoteRole
          richText={blockTeamQuote.source.titleRichText}
          copySansSize="small"
        />
      </SourceWrapper>
      <QuoteWrapper>
        <QuoteMark $isOpen aria-hidden />
        <Quote text={blockTeamQuote.quote} />
        <QuoteMark aria-hidden />
      </QuoteWrapper>
      {blockTeamQuote.image && (
        <MediaWrapper ref={ref}>
          <Media media={blockTeamQuote.image} aspectRatio="1:1" />
        </MediaWrapper>
      )}
    </BlockTeamQuoteWrapper>
  );
};

export default BlockTeamQuote;
