import styled, {css} from 'styled-components';

import BlockCarousel from '@/components/Blocks/BlockCarousel';
import Heading from '@/components/Heading';
import RichTextChunk from '@/components/RichTextChunk';
import {
  COLOR_ALMOST_BLACK,
  COLOR_BLACK,
  COLOR_LATTE,
  COLOR_WHITE,
} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S, SPACING_XXL} from '@/theme/spacings';
import {TYPE_SIZE_LARGE} from '@/theme/type';

interface ThemeProps {
  $backgroundColor: boolean;
}

export const BlockMilestonesWrapper = styled.div<ThemeProps>`
  position: relative;
  background: ${({$backgroundColor}) =>
    $backgroundColor ? COLOR_ALMOST_BLACK : COLOR_LATTE};
  padding-top: ${SPACING_XXL};
  margin: 0 -${gridGutters(1)};

  /**
   * For black on black backgrounds.
   * Removes little line that's sometimes visible.
   */
  margin-top: -1px;
`;

interface HeadingProps extends ThemeProps {
  $mobile?: boolean;
}

export const StyledHeading = styled(Heading)<HeadingProps>`
  margin: 0;
  color: ${({$backgroundColor}) =>
    $backgroundColor ? COLOR_WHITE : COLOR_BLACK};
  padding: 0 ${gridGutters(1)};
  text-align: center;

  display: ${({$mobile}) => ($mobile ? 'initial' : 'none')};
  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding: 0 ${gridGutters(3)};
    display: ${({$mobile}) => ($mobile ? 'none' : 'initial')};
  }
`;

export const StyledRichTextChunk = styled(RichTextChunk)<ThemeProps>`
  margin-top: ${SPACING_S};
  padding: 0 ${gridGutters(1)};
  text-align: center;
  color: ${({$backgroundColor}) =>
    $backgroundColor ? COLOR_WHITE : COLOR_BLACK};

  p {
    padding: 0;
    ${TYPE_SIZE_LARGE}
  }

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding: 0;
    padding: 0 ${gridGutters(7)};
    text-align: center;
  }
`;

interface BlocksWrapperProps {
  $isFullWidthCarousel: boolean;
}

export const BlocksWrapper = styled.div<BlocksWrapperProps>`
  ${({$isFullWidthCarousel}) =>
    !$isFullWidthCarousel &&
    css`
      margin-left: ${gridGutters(3)};

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-left: ${gridGutters(5)};
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        margin-left: ${gridGutters(6)};
      }
    `}

  &:last-of-type {
    padding-bottom: ${SPACING_XXL};
  }
`;

export const StyledBlockCarousel = styled(BlockCarousel)`
  padding-left: 0;
  margin-top: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_L};
  }
`;
