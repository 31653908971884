import {Block, ParagraphWithMedia} from '@/content/cms/types';

import {BlockProps} from '..';
import Column from './Column';
import {
  BlockMediaColumnsWrapper,
  StyledHeading,
  StyledCopy,
  Columns,
} from './styles';

export interface BlockMediaColumnsProps extends Block {
  heading: string;
  copy: string;
  leftColumn: ParagraphWithMedia;
  rightColumn: ParagraphWithMedia;
}

const BlockColumns = ({block}: BlockProps) => {
  const blockMediaColumns = block as BlockMediaColumnsProps;

  return (
    <BlockMediaColumnsWrapper>
      <StyledHeading level={3} animate>
        {blockMediaColumns.heading}
      </StyledHeading>
      <StyledCopy serifSize="large">{blockMediaColumns.copy}</StyledCopy>
      <Columns>
        <Column paragraphWithMedia={blockMediaColumns.leftColumn} />
        <Column paragraphWithMedia={blockMediaColumns.rightColumn} />
      </Columns>
    </BlockMediaColumnsWrapper>
  );
};

export default BlockColumns;
