import {motion} from 'framer-motion';
import styled from 'styled-components';

import {BORDER_RADIUS_EXTRA_LARGE, onFocusOutline} from '@/theme/borders';
import {COLOR_BLACK_10, COLOR_ORANGE} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_S} from '@/theme/spacings';

export const PaginationDotsWrapper = styled(motion.div)`
  display: none;
  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: flex;
    margin-top: ${SPACING_L};
    margin-left: ${gridGutters(1)};
  }
`;

interface DotProps {
  $active: boolean;
}

export const Dot = styled(motion.button)<DotProps>`
  margin-right: ${SPACING_S};
  border: none;
  height: ${pxToRem(7)};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  display: block;
  cursor: pointer;
  background: ${({$active}) => ($active ? COLOR_ORANGE : COLOR_BLACK_10)};
  will-change: width;
  min-width: ${pxToRem(7)};

  ${onFocusOutline()};
`;
