import {Block, Link, RichText} from '@/content/cms/types';

import {BlockProps} from '..';
import LineChart from './LineChart';

export interface BlockLineChartProps extends Block {
  lineChartTitle: RichText;
  sourceLabel: string;
  dataSourceOne: Link;
  dataSourceTwo: Link;
  tooltipOneCopy: string;
  tooltipTwoCopy: string;
}

const BlockLineChart = ({block}: BlockProps) => {
  const blockLineChart = block as BlockLineChartProps;
  return (
    <LineChart
      title={blockLineChart.lineChartTitle}
      sourceLabel={blockLineChart.sourceLabel}
      tooltips={[
        {
          copy: blockLineChart.tooltipOneCopy,
          source: blockLineChart.dataSourceOne,
        },
        {
          copy: blockLineChart.tooltipTwoCopy,
          source: blockLineChart.dataSourceTwo,
        },
      ]}
    />
  );
};

export default BlockLineChart;
